const CHAT = [
    {
      user: "people",
      colorText: "",
      name: "Ryan",
      text: "Why do I need to pay transfer fees?",
      attach: { img: null },
      time: null,
    },
    {
      user: !1,
      colorText: "",
      name: "Support service",
      text: "Ryan, This commission is established between your bank and the sender's bank. We cannot pay the commission for you. It depends on the beneficiary's bank and the withdrawal amount.",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Leonid",
      text: "I personally received the payment, Thanks to Manager Dmitry Rogozin! A very cool guy. And the commission cost me a small amount, but the money came immediately!",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Andrew",
      text: "Hooray!!! Got!! I am extremely happy!!! How many nerves did it cost me",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Aaron",
      text: "I sat, hesitated, but still decided to pay the commission. Thanks a lot!",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Lili",
      text: "And I waited five minutes in total))))) Maybe it depends on the region)))",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Frad",
      text: "I was waiting for this money for my mother for treatment. Thank you heaven! Thank you, these 139 thousand will help my mother a lot. Happiness to you and prosperity!",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Nika",
      text: "Cool. $480 got every penny. Thanks for the translation. The only thing is, I waited over an hour!",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Ella",
      text: "Bitcoin Bonus why didn't the money come for the first transfer?",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Helen",
      text: "Ella, I was also indignant at first. I waited a couple of hours, but they never came .. Then I read the bank conditions, the bitcoin bonus had nothing to do with it, these are billings. Waiting for the payment of the second commission. I paid the second commission and the money instantly came)",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Frad",
      text: "Ella, read carefully, this is a commission for the second transfer. For those who have amounts over 700 000 руб. I paid and received. Do not spend all your money in one place as one great one said)",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Brandon",
      text: "I want to thank you, the promised amount came to my card, I am very, very happy!",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Victoria",
      text: "Received. 786 698 confirm!",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Kira",
      text: "So they are not to blame that the payment system has a commission.",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Lev",
      text: "And I got it) And I'm SHOCKED by such a large demand for bitcoin today! Brought out 712thous.",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "German",
      text: "Who guarantees me that after paying the commission I will receive my money? Well, let's try it, it won't hurt!",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Kate",
      text: "Made money on Bitcoin World, paid for everything they asked for. But I had to wait more than 3 months until I contacted Bitcoin Bonus myself. What can I say, I already have 712 thous. руб on my map :)",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Dasha",
      text: "Wow you have a great idea!! At least you don't have to pay income tax. I paid for the express transfer and I feel calm. The money came to the card as a payment to an individual. I received my 78,000 and am very, very happy!! HERE and my screen",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Nathan",
      text: "Cunningly done with express transfer. But our money is saved. As I understand it, it turns out that I received the money as a physical transfer, and not as a profit. Therefore, there is no tax. Honor and praise for the mind. 800$ received.",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Tais",
      text: "Really pay! I didn't believe until the last..... Bitcoin Bonus are the best! I'm going to buy more tickets to the loto))",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Maria",
      text: "Class! Today I am the happiest, let's go today with my husband to the most expensive restaurant!!! This is amazing, thank you all!))))))",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Anna",
      text: "I'm delighted!!! I don’t know how to express my gratitude to you, it’s something with something, I have no more words!) Thank you very much!!!))))))))))))))))))",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Isaac",
      text: "I lived practically on the street, and how glad I was to hear from you! I paid the express invoice, received the money, and did not even pay the personal income tax",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Lev",
      text: "And I got it) And I'm SHOCKED by such a large demand for bitcoin today! Brought out 712thous.",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "German",
      text: "Who guarantees me that after paying the commission I will receive my money? Well, let's try it, it won't hurt!",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Kate",
      text: "Made money on Bitcoin World, paid for everything they asked for. But I had to wait more than 3 months until I contacted Bitcoin Bonus myself. What can I say, I already have 712 thous. руб on my map :)",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Mason",
      text: "It's true)) $3566 flew into the card. As an avid fisherman, I am supposed to spend on spinning and tackle. Fishing is close)",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Cody",
      text: "Wow the money has arrived!!! Feel free to enter the payment in the cadastre, otherwise there will be no payment!",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Brianna",
      text: "How glad I am that after entering my personal remuneration for questions in the amount of 1753$ into the cadastre, I received them all on a savings bank card. Praise for such an honest project!",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Kylie",
      text: "I paid for the reservation of the transit cell, the transfer arrived! I thank you from the bottom of my heart!",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Rachel",
      text: "I thought the money would not come, but everything is fine, 10350 usd came after the reservation. Thank you very much!",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Samantha",
      text: "I confirm, I received the agreed money 14550 after booking",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Justin",
      text: "WOW that's what a transit cell is a very convenient thing, the money from Bitcoin Bonus came quickly.",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Andrew",
      text: "Money received on the card. Arrived 5 minutes after booking.",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Jacob",
      text: "After booking a transit cell, I received my payment. Thank you!",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Anna",
      text: "I don't know.. I'll try... I just give the last money. (added) Wow! Come, no doubt. Guys, get yours soon!",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Kylie",
      text: "I paid for the reservation of the transit cell, the transfer arrived! I thank you from the bottom of my heart!",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Rachel",
      text: "I thought the money would not come, but everything is fine, 10350 usd came after the reservation. Thank you very much!",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Samantha",
      text: "I confirm, I received the agreed money 14550 after booking",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Justin",
      text: "WOW that's what a transit cell is a very convenient thing, the money from Bitcoin Bonus came quickly.",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Andrew",
      text: "Money received on the card. Arrived 5 minutes after booking.",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Jacob",
      text: "After booking a transit cell, I received my payment. Thank you!",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Anna",
      text: "I don't know.. I'll try... I just give the last money. (added) Wow! Come, no doubt. Guys, get yours soon!",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Rahim",
      text: "MAXiMUS, I'm more than sure that you do the same))) It's stupid to mine from one device, there is never enough money)😁",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Emma",
      text: "😳I have not been so happy for a long time, today's bitcoin rate is just happy!",
      attach: { img: "qiwi.jpg" },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Andrew",
      text: "Wow, what can really overheat the phone???🤭",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "NOiSy",
      text: "Andrew, no, he won't get anything! You listen to more of these idiots that they write here! I have been making money on my iPhone for the 2nd year at full capacity and nothing to it!",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "John",
      text: "Emma, well, no matter what anyone says, but I am sure that the future belongs to cryptocurrencies. Soon paper money will lose its value in this world!🤫",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "David J.",
      text: "Of course nothing will happen! Who writes this, he quietly buys a bunch of phones and computers in order to collect more crypto with the help of this service!",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Andrew",
      text: "Thank you! Convinced me! I will keep farming.😋",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "HotPepper05",
      text: "!!! I'm here 100 phones 📱📱📱 I can connect yours",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Olga",
      text: "No thanks!😤",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Akmal",
      text: 'HotPepper05, you again drunk strong drinks and wake up here "clownado" show?🤡',
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "HotPepper05",
      text: "Ys, I d't care",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "HotPepper05",
      text: "how many ordinary onlookers I registered here - you can’t even imagine 🤬",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "NOiSy",
      text: "HotPepper05, let me guess. Mining crypto from their phones without them knowing?",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "System message",
      text: "HotPepper05 - user with this login has been blocked",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "WhiteT: (Administrator)",
      text: "User ID:55901228 was blocked for violating the rules of service. The IP of the blocked device is not available for cloud mining. Enjoy Bitcoin Bonus!",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Olga",
      text: "Thank you! And that spoils the mood, and today is a great day for withdrawal!🤑",
      attach: { img: "alfa1.jpg" },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "NOiSy",
      text: "WhiteT, I almost got to the bottom of it, I had to wait)",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "WhiteT: (Administrator)",
      text: "NOiSy, we understand everything about this character. We try to maintain on the Bitcoin Bonus servers not only uninterrupted operation, but also pleasant communication.",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "NOiSy",
      text: "Understand! I joke like that))",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Kaylee",
      text: "Hello! I'm new here, I just switched to the site, and it turns out I've been here for like a whole month. And accumulated 5,000! How to withdraw? Tell me please!",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "NOiSy",
      text: "Kaylee, oh you're not the first person to write about this today))",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Mary",
      text: "Kaylee, you're lucky you found out in time! This happens when the phone is either new or connected to Bitcoin Bonus through some downloaded application. And scammers grumble from your phone in this way! If you logged in, they either forgot or haven't gotten to your phone yet",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Andrew",
      text: "NOiSy, that's lucky!)) I would like that from the beginning, I went in and immediately 45k))",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Kaylee",
      text: "but how to get it out ??? Fraudsters won't take it?!!",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Rahim",
      text: "Kaylee, you need to enter my card))😈",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Mary",
      text: "Kaylee, don’t worry, if you were able to enter your personal account, then they will lose access. This is the security system",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Bryan",
      text: "All the best! How are you doing?)) What about the course, why has it grown so much?? Did you withdraw all the money today?",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "NOiSy",
      text: "Rahim, you are the richest and most cunning here, what is your secret?!🤠",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Andrew",
      text: "It has begun, it will again argue about who has the income, phone, etc. is better!! Enough please",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Kaylee",
      text: "Mary, HOW TO BRING HELP! I will pay you!",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Mary",
      text: "Kaylee, I don't want your money! I make good money here🙄",
      attach: { img: "qiwi2.jpg" },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Mary",
      text: "Kaylee, first, collect all the bitcoin bonuses that the system asks for withdrawal!",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Kaylee",
      text: "Okay, I'll do it now!",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Rahim",
      text: "No one here is going to argue with him. I already told him my opinion about him! I think we understand each other!))",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "NOiSy",
      text: "Rahim, of course my dear friend!)",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Andrew",
      text: "Kaylee, everything is very simple there! It's time to ask the administration to create new chats! so that newcomers do not chirp here!)",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Kaylee",
      text: "Mary, I did! Should I press output??",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Mary",
      text: "Andrew, Not everyone understands what's what from the very beginning! Be patient please! Or if you know how to earn a lot here, share your experience with newbies! Chat will be more enjoyable!",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Mary",
      text: "Kaylee, yes, you press the button to withdraw. Then follow the instructions of the system. If you do not have a personal Swift gateway, you can rent it on the site, a very convenient thing. especially if Plus",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "NOiSy",
      text: 'I support you! There are, of course, a bunch of "Standards", they are all unanimous, will there be payments today)) Poor damn',
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Vlad",
      text: "People! Hi everyone! And how to get to the Russian site Bitcoin Bonus. I want to chat about mining with Russians)",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "LolaBTC",
      text: "Vlad, write to the manager, they can send you to western servers. Only this is not accurate, your IP is here",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Alexander",
      text: "Vlad, friend, what to do there ?! They have the same stupid flood there, there would be moderators!",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Arianna",
      text: "Hello! Can you please tell me if I can withdraw bitcoins immediately to my wallet in binance?",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "NOiSy",
      text: 'Arianna No. Such a function is not available on BTCuPAY yet it is possible. But they pay less for mining there... And they don’t give Swift there, - “look for youruser, as they say, how to withdraw from us!"',
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Kaylee",
      text: "THANK YOU VERY MUCH! I still managed to get all $4500 on the card now! Thank you bitcoin bonus",
      attach: { img: "sber.jpg" },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Daud78",
      text: "All Health and Kindness!",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Jscience0",
      text: "Daud, and you are a kind person! Ready to withdraw a bunch of bonuses today?)",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Max Pavlov",
      text: "Kaylee, will you continue to use the service?",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Kaylee",
      text: "Maxim, of course! It would be foolish to refuse to still not believe in such luck!",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "David",
      text: "Kaylee, there is even more than just luck) 45 thousand today, next time you will withdraw 15 thousand dollars! We are saving everything here and we are waiting for a good course)",
      attach: { img: "tinkof1.jpg" },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "MaryMaru",
      text: "I try not to tell anyone I know that you can earn money this way! I usually just link their phones to my account and earn on it))",
      attach: { img: "qiwi1.jpg" },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Nikola19",
      text: "MaryMaru, don't you find this a bit mean?!",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Roberto",
      text: "oooh. I didn’t expect such a game here, so this is what those who abuse other people’s phones look like",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Kaylee",
      text: "MaryMaru, I'm lucky that I saw during the SMS in the mail about blocking the account!",
      attach: { img: "tinkof2.jpg" },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "MaryMaru",
      text: "Congratulations)))",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "FranklinRus",
      text: "be like that!",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "MaryMaru",
      text: "fuck you all! I have a lot of money as well as accounts here!",
      attach: { img: "qiwi3.jpg" },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "System message",
      text: "MaryMaru - user with this login has been blocked",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "WhiteT: (Administrator) ",
      text: "User ID:19901228 was blocked for violating the rules of the service. The IP of the blocked device is not available for cloud mining. Enjoy Bitcoin Bonus!",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "FranklinRus",
      text: "Here comes the ban!))",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Roberto",
      text: "WhiteT, very timely thanks!",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "WhiteT: (Administrator)",
      text: "We make sure that the servers have a pleasant atmosphere in everything.",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Daud78",
      text: "WhiteT, noticeably!",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Daud78",
      text: "Friends! The course is just amazing! Output",
      attach: { img: "tinkof3.jpg" },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "NOiSy",
      text: "Daud78, It is already slowly declining, better hurry up, everything has already been withdrawn here))",
      attach: { img: null },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Erin",
      text: "Daud78, Get it out soon! You will lose a lot, I have already lost 2 thousand",
      attach: { img: "sber1.jpg" },
      time: null,
    },
    {
      user: "people",
      colorText: "",
      name: "Rich",
      text: "I earned more than 17000 dollars on this service! Get rich now!",
      attach: { img: "sber2.jpg" },
      time: null,
    },
  ];

  export default CHAT;