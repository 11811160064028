import "./transaction.css";
import { useEffect, useState } from "react";
import axios from "axios";
import tag from "../../img/tag.svg";
import bitcoin from "../../img/btc.png";
import eth from "../../img/eth.svg";
import litecoin from "../../img/litecoin.svg";
import matic from "../../img/matic.svg";
import ethmatic from "../../img/eth-matic.png";
import tether from "../../img/tether.svg";

function Transaction(props) {
  const NAME = "StarkRDP";
  const INVOICE_NUMBER = "#75149";
  const INVOICE_NUMBER_LONG = "#010755715";
  const CRYPTO_TEXT = [
    "Open your crypto wallet and scan the QR code, or copy BTC below and make a payment.",
    "Open your crypto wallet and scan the QR code, or copy ETH below and make a payment.",
    "Open your crypto wallet and scan the QR code, or copy LTC below and make a payment.",
    "Open your crypto wallet and scan the QR code, or copy USDT below and make a payment.",
    "Open your crypto wallet and scan the QR code, or copy MATIC below and make a payment.",
    "Open your crypto wallet and scan the QR code, or copy WRAPPED ETHER below and make a payment.",
  ];
  const [btcV, setbtc] = useState(false);
  const [ethV, seteth] = useState(false);
  const [ltcV, setltc] = useState(false);
  const [thV, setth] = useState(false);
  const [ethM, setethM] = useState(false);
  const [mV, setm] = useState(false);
  const [activeProcess, setActiveProcess] = useState(false);
  const [minutes, setMinutes] = useState(60);
  const [seconds, setSeconds] = useState(0);
  const [timer, settimer] = useState(false);
  const [coins, setList] = useState([]);
  const initialValue = (
    ((props.bitcoinPrice * props.balanceValue).toFixed(2) / 100) *
    0.25
  ).toFixed(2);
  const [supportBox, setsupportBox] = useState(false);

  const copyToClipboard = async (e) => {
    const text = e.currentTarget.parentNode.querySelector("b").innerText;
    try {
      await navigator.clipboard.writeText(text);
      console.log("Text copied to clipboard");
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  useEffect(() => {
    axios
      .get(
        "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=bitcoin%2Cethereum%2Cmatic-network%2Cwrapped-ethw%2Clitecoin%2Ctether&order=market_cap_desc&per_page=20&page=1&sparkline=false"
      )
      .then((res) => {
        setList(res.data);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    let interval = null;
    if (minutes === 0 && seconds === 0) {
      clearInterval(interval);
      settimer(true);
      return;
    }
    interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [minutes, seconds]);

  function setToBTC() {
    setbtc(true);
    setActiveProcess(true);
  }
  function setToETH() {
    seteth(true);
    setActiveProcess(true);
  }
  function setToLTC() {
    setltc(true);
    setActiveProcess(true);
  }
  function setToTH() {
    setth(true);
    setActiveProcess(true);
  }
  function setToETHM() {
    setethM(true);
    setActiveProcess(true);
  }
  function setToMat() {
    setm(true);
    setActiveProcess(true);
  }
  function backAction() {
    setActiveProcess(false);
    setbtc(false);
    seteth(false);
    setltc(false);
    setth(false);
    setethM(false);
    setm(false);
  }
  function handleForm(e) {
    setsupportBox(false)
    e.preventDefault()
  }

  return (
    <div className="frame">
      <div className="frame-inner">
        <div className="frame-left">
          <div className="frame-left-inner">
            <div className="frame-left-title">{NAME}</div>
            <div className="frame-left-bottom">
              <div className="frame-left-bottom-title">
                {NAME} - Invoice
                <br />
                {INVOICE_NUMBER_LONG}
              </div>
              <div className="frame-left-bottom-subtitle">
                Invoice - {INVOICE_NUMBER}
              </div>
            </div>
          </div>
        </div>
        <div className="frame-right">
          <div className="frame-right-inner">
            <div className="frame-right-block">
              <div className="inv-block">
                <div className="inv-block-icon">
                  <img alt="Bitcoin" src={tag} />
                  <div className="inv-block-plus">+</div>
                </div>
                <div className="inv-block-content">
                  <div className="inv-block-title">
                    {NAME} - Invoice {INVOICE_NUMBER_LONG}
                  </div>
                  <div className="inv-block-subtitle">
                    Invoice - {INVOICE_NUMBER}
                  </div>
                </div>
              </div>
            </div>
            {!activeProcess && (
              <div className="frame-right-block">
                <div className="payblock">
                  <div className="payblock-method">
                    <div className="payblock-method-title">Payment method</div>
                    <div className="payblock-method-time">
                      {minutes}:{seconds}
                    </div>
                  </div>
                  <div className="payblock-total">
                    <div className="payblock-total-title">Total</div>
                    <div className="payblock-total-price">${initialValue}</div>
                  </div>
                  <div className="payblock-checkout">
                    <div className="payblock-or">
                      Select a cryptocurrency to pay with another wallet
                    </div>
                    <div className="payblock-cryptos">
                      <div onClick={setToBTC} className="payblock-cryptos-col">
                        <a className="payblock-cryptos-item">
                          <div className="payblock-cryptos-left">
                            <div className="payblock-cryptos-icon">
                              <img alt="Bitcoin" src={bitcoin} />
                            </div>
                            <div className="payblock-cryptos-title">
                              Bitcoin
                            </div>
                          </div>
                          <div className="payblock-cryptos-name">BTC</div>
                        </a>
                      </div>
                      <div onClick={setToETH} className="payblock-cryptos-col">
                        <a className="payblock-cryptos-item">
                          <div className="payblock-cryptos-left">
                            <div className="payblock-cryptos-icon">
                              <img alt="Bitcoin" src={eth} />
                            </div>
                            <div className="payblock-cryptos-title">
                              Etherium
                            </div>
                          </div>
                          <div className="payblock-cryptos-name">ETH</div>
                        </a>
                      </div>
                      <div onClick={setToLTC} className="payblock-cryptos-col">
                        <a className="payblock-cryptos-item">
                          <div className="payblock-cryptos-left">
                            <div className="payblock-cryptos-icon">
                              <img alt="Bitcoin" src={litecoin} />
                            </div>
                            <div className="payblock-cryptos-title">
                              Litecoin
                            </div>
                          </div>
                          <div className="payblock-cryptos-name">LTC</div>
                        </a>
                      </div>
                      <div onClick={setToTH} className="payblock-cryptos-col">
                        <a className="payblock-cryptos-item">
                          <div className="payblock-cryptos-left">
                            <div className="payblock-cryptos-icon">
                              <img alt="Bitcoin" src={tether} />
                            </div>
                            <div className="payblock-cryptos-title">Tether</div>
                          </div>
                          <div className="payblock-cryptos-name">USDT</div>
                        </a>
                      </div>
                    </div>
                    <div className="payblock-new">
                      <div className="payblock-new-icon">NEW</div>
                      <div className="payblock-new-title">
                        Now available on Polygon network{" "}
                      </div>
                      <div className="payblock-new-info">i</div>
                    </div>
                    <div className="payblock-cryptos mt0">
                      <div onClick={setToMat} className="payblock-cryptos-col">
                        <a className="payblock-cryptos-item">
                          <div className="payblock-cryptos-left">
                            <div className="payblock-cryptos-icon">
                              <img alt="Bitcoin" src={matic} />
                            </div>
                            <div className="payblock-cryptos-title">Matic</div>
                          </div>
                          <div className="payblock-cryptos-name">MATIC</div>
                        </a>
                      </div>
                      <div onClick={setToETHM} className="payblock-cryptos-col">
                        <a className="payblock-cryptos-item">
                          <div className="payblock-cryptos-left">
                            <div className="payblock-cryptos-icon">
                              <img alt="Bitcoin" src={ethmatic} />
                            </div>
                            <div className="payblock-cryptos-title">
                              Wrapped Ether
                            </div>
                          </div>
                          <div className="payblock-cryptos-name">ETH</div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {activeProcess && (
              <div className="frame-right-block">
                <div className="payblock">
                  <div className="payblock-method">
                    <div className="payblock-method-title-wrap">
                      <div className="payblock-method-title">Send payment</div>
                      <div className="payblock-method-subtitle">
                        {btcV ? CRYPTO_TEXT[0] : ""}
                        {ethV ? CRYPTO_TEXT[1] : ""}
                        {ltcV ? CRYPTO_TEXT[2] : ""}
                        {thV ? CRYPTO_TEXT[3] : ""}
                        {mV ? CRYPTO_TEXT[4] : ""}
                        {ethM ? CRYPTO_TEXT[5] : ""}
                      </div>
                    </div>
                    <div className="payblock-method-time">
                      {minutes}:{seconds}
                    </div>
                  </div>

                  {timer && (
                    <div className="smile-block">
                      <span>🙁</span>
                      <p>We did not get the payment</p>
                    </div>
                  )}

                  {btcV && !timer && (
                    <div>
                      <div className="payblock-qr">
                        <div className="payblock-pic">
                          <div className="qr-icon">
                            <img alt="Bitcoin" src={bitcoin} />
                          </div>
                          {props.uniqueQR.length === 6 ? (
                            <img
                              alt="Bitcoin"
                              src={
                                props.uniqueQR.find(
                                  (item) => item.name === "BTC/"
                                ).url || ""
                              }
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="payblock-address">
                        <div className="payblock-address-content">
                          <span>BTC</span> Address
                          <br />
                          <b id="address">{props.cdata["BTC"]}</b>
                        </div>
                        <div
                          onClick={copyToClipboard}
                          className="payblock-address-copy copy-address"
                        >
                          Copy
                        </div>
                      </div>
                      <div className="payblock-address payblock-amount">
                        <div className="payblock-address-content">
                          <span>Total amount</span>
                          <br />
                          <b id="amount">
                            {(initialValue / coins[0].current_price).toFixed(8)}{" "}
                            <i>BTC</i>
                          </b>
                        </div>
                        <div
                          onClick={copyToClipboard}
                          className="payblock-address-copy copy-amount"
                        >
                          Copy
                        </div>
                      </div>
                    </div>
                  )}
                  {ethV && !timer && (
                    <div>
                      <div className="payblock-qr">
                        <div className="payblock-pic">
                          <div className="qr-icon">
                            <img alt="Bitcoin" src={eth} />
                          </div>
                          {props.uniqueQR.length === 6 ? (
                            <img
                              alt="Bitcoin"
                              src={
                                props.uniqueQR.find(
                                  (item) => item.name === "ETH/"
                                ).url || ""
                              }
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="payblock-address">
                        <div className="payblock-address-content">
                          <span>ETH</span> Address
                          <br />
                          <b id="address">{props.cdata["ETH"]}</b>
                        </div>
                        <div
                          onClick={copyToClipboard}
                          className="payblock-address-copy copy-address"
                        >
                          Copy
                        </div>
                      </div>
                      <div className="payblock-address payblock-amount">
                        <div className="payblock-address-content">
                          <span>Total amount</span>
                          <br />
                          <b id="amount">
                            {(initialValue / coins[1].current_price).toFixed(8)}{" "}
                            <i>ETH</i>
                          </b>
                        </div>
                        <div
                          onClick={copyToClipboard}
                          className="payblock-address-copy copy-amount"
                        >
                          Copy
                        </div>
                      </div>
                    </div>
                  )}
                  {ltcV && !timer && (
                    <div>
                      <div className="payblock-qr">
                        <div className="payblock-pic">
                          <div className="qr-icon">
                            <img alt="Bitcoin" src={litecoin} />
                          </div>
                          {props.uniqueQR.length === 6 ? (
                            <img
                              alt="Bitcoin"
                              src={
                                props.uniqueQR.find(
                                  (item) => item.name === "LTC/"
                                ).url || ""
                              }
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="payblock-address">
                        <div className="payblock-address-content">
                          <span>LTC</span> Address
                          <br />
                          <b id="address">{props.cdata["LTC"]}</b>
                        </div>
                        <div
                          onClick={copyToClipboard}
                          className="payblock-address-copy copy-address"
                        >
                          Copy
                        </div>
                      </div>
                      <div className="payblock-address payblock-amount">
                        <div className="payblock-address-content">
                          <span>Total amount</span>
                          <br />
                          <b id="amount">
                            {(initialValue / coins[4].current_price).toFixed(8)}{" "}
                            <i>LTC</i>
                          </b>
                        </div>
                        <div
                          onClick={copyToClipboard}
                          className="payblock-address-copy copy-amount"
                        >
                          Copy
                        </div>
                      </div>
                    </div>
                  )}
                  {thV && !timer && (
                    <div>
                      <div className="payblock-qr">
                        <div className="payblock-pic">
                          <div className="qr-icon">
                            <img alt="Bitcoin" src={tether} />
                          </div>
                          {props.uniqueQR.length === 6 ? (
                            <img
                              alt="Bitcoin"
                              src={
                                props.uniqueQR.find(
                                  (item) => item.name === "USDT/"
                                ).url || ""
                              }
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="payblock-address">
                        <div className="payblock-address-content">
                          <span>USDT</span> Address
                          <br />
                          <b id="address">{props.cdata["USDT"]}</b>
                        </div>
                        <div
                          onClick={copyToClipboard}
                          className="payblock-address-copy copy-address"
                        >
                          Copy
                        </div>
                      </div>
                      <div className="payblock-address payblock-amount">
                        <div className="payblock-address-content">
                          <span>Total amount</span>
                          <br />
                          <b id="amount">
                            {(initialValue / coins[2].current_price).toFixed(8)}{" "}
                            <i>USDT</i>
                          </b>
                        </div>
                        <div
                          onClick={copyToClipboard}
                          className="payblock-address-copy copy-amount"
                        >
                          Copy
                        </div>
                      </div>
                    </div>
                  )}
                  {mV && !timer && (
                    <div>
                      <div className="payblock-qr">
                        <div className="payblock-pic">
                          <div className="qr-icon">
                            <img alt="Bitcoin" src={matic} />
                          </div>
                          {props.uniqueQR.length === 6 ? (
                            <img
                              alt="Bitcoin"
                              src={
                                props.uniqueQR.find(
                                  (item) => item.name === "MATIC/"
                                ).url || ""
                              }
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="payblock-address">
                        <div className="payblock-address-content">
                          <span>MATIC</span> Address
                          <br />
                          <b id="address">{props.cdata["MATIC"]}</b>
                        </div>
                        <div
                          onClick={copyToClipboard}
                          className="payblock-address-copy copy-address"
                        >
                          Copy
                        </div>
                      </div>
                      <div className="payblock-address payblock-amount">
                        <div className="payblock-address-content">
                          <span>Total amount</span>
                          <br />
                          <b id="amount">
                            {(initialValue / coins[3].current_price).toFixed(8)}{" "}
                            <i> MATIC</i>
                          </b>
                        </div>
                        <div
                          onClick={copyToClipboard}
                          className="payblock-address-copy copy-amount"
                        >
                          Copy
                        </div>
                      </div>
                    </div>
                  )}
                  {ethM && !timer && (
                    <div>
                      <div className="payblock-qr">
                        <div className="payblock-pic">
                          <div className="qr-icon">
                            <img alt="Bitcoin" src={ethmatic} />
                          </div>
                          {props.uniqueQR.length === 6 ? (
                            <img
                              alt="Bitcoin"
                              src={
                                props.uniqueQR.find(
                                  (item) => item.name === "WETH/"
                                ).url || ""
                              }
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="payblock-address">
                        <div className="payblock-address-content">
                          <span>ETH</span> Address
                          <br />
                          <b id="address">{props.cdata["WETH"]}</b>
                        </div>
                        <div
                          onClick={copyToClipboard}
                          className="payblock-address-copy copy-address"
                        >
                          Copy
                        </div>
                      </div>
                      <div className="payblock-address payblock-amount">
                        <div className="payblock-address-content">
                          <span>Total amount</span>
                          <br />
                          <b id="amount">
                            {(initialValue / coins[5].current_price).toFixed(8)}{" "}
                            <i>ETH</i>
                          </b>
                        </div>
                        <div
                          onClick={copyToClipboard}
                          className="payblock-address-copy copy-amount"
                        >
                          Copy
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {activeProcess && (
              <div>
                {timer && (
                  <div onClick={() => {setsupportBox(true)}} className="sup-connect payblock-checkout-paybutton">
                    Connect to support
                  </div>
                )}
                <div className="payblock-back-wrap">
                  <div onClick={backAction} className="payblock-back">
                    Back
                  </div>
                </div>
              </div>
            )}
            {activeProcess && (
              <div className="payblock-credit">
                Payments processed by <b>Coinbase commerce.</b> View{" "}
                <a href="#">Privacy Policy</a>
              </div>
            )}
          </div>
        </div>
      </div>

      {supportBox && (
        <div className="pleaseForm">
          <div className="pleaseInner">
            <div onClick={() => { setsupportBox(false) }} className="cross">x</div>
            <form action="#" onSubmit={(e) => {handleForm(e)}}>
              <p>Please add the transaction ID and E-mail</p>
              <input type="text" placeholder="Transaction ID" required />
              <input type="email" placeholder="Email" required />
              <button type="submit" className="btn btn-default">Send</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default Transaction;
