import "./footer.css";
import logo from "../../img/logo.svg";

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-inner">
          <div className="logo footer__logo">
            <img src={logo} alt="BitBonus" />
            <span>Bitcoin Bonus</span>
          </div>
          <div className="col-12 col-md-7 text-center text-md-right socnets">
            <span
              rel="nofollow"
              target="_blank"
              className="ms-0 me-1 text-white no-select fb-ic"
            >
              <i className="fa-brands fa-facebook"></i>
              <small className="pe-2 text-numbers">27.6k</small>
            </span>
            <span
              rel="nofollow"
              target="_blank"
              className="ms-0 me-1 text-white no-select tw-ic"
            >
              <i className="fa-brands fa-twitter"></i>
              <small className="pr-2 text-numbers">34.8k</small>
            </span>
            <span
              rel="nofollow"
              target="_blank"
              className="ms-0 me-1 text-white no-select in-ic"
            >
              <i className="fa-brands fa-instagram"></i>
              <small className="pr-2 text-numbers">78.2k</small>
            </span>
            <span
              rel="nofollow"
              target="_blank"
              className="ms-0 text-white no-select yt-ic"
            >
              <i className="fa-brands fa-youtube"></i>
              <small className="pr-2 text-numbers">59.6k</small>
            </span>
          </div>
        </div>
      </div>
      <div className="rights">
        <p>18641 Shenandoah Dr, Oregon City, OR, 97045</p>
        <p>© 2019—2022. All rights reserved</p>
        <a href="#">Privacy Policy</a>
        <a href="#">User agreement (contract offer)</a>
      </div>
    </footer>
  );
}

export default Footer;
