import "./startpage.css";
import Login from "./Login/Login";
import Popups from './Popups/Popups';
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

function StartPage(props) {

  return (
    <div className="whited">
      <Login  username={props.username} password={props.password} />
      {/* <Popups username={props.username} /> */}
    </div>
  );
}

export default StartPage;
