import { useEffect, useState } from "react";
import "./auth.css";
import bcrypt from "bcryptjs";
import { useNavigate, Link } from "react-router-dom";

function Auth(props) {
  const [authStatus, setAuthStatus] = useState(false);
  const [errorStatus, setErrorStatus] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    props.getData()
  }, []);
  

  useEffect(() => {
    if (parseInt(localStorage.getItem("admin")) === 1) {
        setAuthStatus(true)
        navigate('/admin/in');
    }
  }, [authStatus]);




  const handleSubmit = async (event) => {
    event.preventDefault();
      try {
        const isValid = await bcrypt.compare(password, props.fdata.password);
        if (isValid && username === props.fdata.username) {
          setAuthStatus(true);
          localStorage.setItem("admin", 1);
          navigate('/admin/in');
        } else {
          setErrorStatus(true);
          setTimeout(() => {
            setErrorStatus(false);
          }, 2000);
        }
      } catch (error) {
        console.error(error);
      }
  };




  return (
      <div className="authWrapper">
        <div className="adminAuth">
          <div className="login-content">
            <form onSubmit={handleSubmit}>
              <h2 className="title">Log In</h2>
              <div className="input-div one">
                <div className="i">
                  <i className="fas fa-user"></i>
                </div>
                <div className="div">
                  <input
                    type="text"
                    className="input"
                    onChange={(event) => setUsername(event.target.value)}
                    value={username}
                    placeholder="Username"
                  />
                </div>
              </div>
              <div className="input-div pass">
                <div className="i">
                  <i className="fas fa-lock"></i>
                </div>
                <div className="div">
                  <input
                    type="password"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    className="input"
                    placeholder="Password"
                  />
                </div>
              </div>
              <input type="submit" className="btn" value="Login" />
              <a className="text-center" href="/"> Go to home </a>
              {errorStatus ? (
                <p className="error">Wrong login or password</p>
              ) : (
                ""
              )}
            </form>
          </div>
        </div>
      </div>
    )
}

export default Auth;
