import "./list.css";
import React, {useState, useEffect} from 'react';
import axios from "axios";
import Coin from "./ListItem/Coin";


function List() {
    const [coins, setList] = useState([]);

    useEffect(() => {
      axios
        .get(
          "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=bitcoin%2Cethereum%2Cstellar%2Csolana%2Cdogecoin%2Cripple%2Ccardano%2Clitecoin%2Ctether&order=market_cap_desc&per_page=20&page=1&sparkline=false"
        )
        .then((res) => {
          setList(res.data)
        })
        .catch((error) => console.log(error));
    }, []);

    let coinList = coins.map((coin, i) => {
        return (
            <Coin key={i} name={coin.name} image={coin.image} symbol={coin.symbol} price={coin.current_price} change={coin.price_change_percentage_24h} />
        )
    })

  return (
    <div className="list__row">
        <div className="list__rowInner">
            <div className="list__part">
                {coinList}
            </div>
            <div className="list__part">
                {coinList}
            </div>
        </div>
    </div>
  );
}

export default List;
