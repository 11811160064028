import { useEffect, useState } from "react";
import "./loader.css";
import Chat from "../Chat/Chat";
import { useNavigate  } from 'react-router-dom';
const TIMER = 10000;
const MAX_VALUE = 1.623558;
const TIMER_GAP = 50;

function Loader(props) {

  const navigate = useNavigate ();
  const [loadItem, generateItem] = useState([]);
  const [progress, setProgress] = useState(0);
  const [balance, setBalance] = useState(props.balanceValue);
  const [loaderVisibility, setLoaderVisibility] = useState(false);
  const [loadResultVisibility, setLoadResultVisibility] = useState(false);
  const bitcoinInitalBalance = localStorage.getItem('biccoinValue');
  
  useEffect(() => {
    if (localStorage.getItem('loaderVisibility') && localStorage.getItem('updatedPrice')) {
      setLoaderVisibility(false);
      setLoadResultVisibility(true);
      setBalance(parseFloat(localStorage.getItem('updatedPrice')));
      if (document.querySelector(".loader")) document.querySelector(".loader").style.display = 'none';
    } else {
      setLoaderVisibility(true);
    }
    console.log('init')
  }, []);

  useEffect(() => {
    if (loaderVisibility) {
      const interval = setInterval(() => {
        setBalance((prevBalance) => {
          const randomIncrement =
            (MAX_VALUE - props.balanceValue) / (TIMER / TIMER_GAP);
          const newBalance = prevBalance + randomIncrement;
          if (newBalance >= MAX_VALUE) {
            clearInterval(interval);
            return newBalance;
          }
          return newBalance;
        });
      }, TIMER_GAP);
  
      const timer = setTimeout(() => clearInterval(interval), TIMER);
      return () => {
        clearInterval(interval);
        clearTimeout(timer);
      };
    }

  }, [setBalance, loaderVisibility]);

  useEffect(() => {

    if (loaderVisibility) {
      const interval = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress >= 100) {
            clearInterval(interval);
            return 100;
          }
          return prevProgress + 1;
        });
      }, TIMER / 100);
      // clear interval on unmounting
      return () => clearInterval(interval);
    }

  }, [loaderVisibility]);

  function changeComponentState(event) {
    event.preventDefault();
    localStorage.setItem('componentState', 3);
    localStorage.setItem('moderatorFinal', false);
    navigate('/moderator');
  }

  useEffect(() => {
    props.setBalanceValue(balance);
  }, [balance]);


  useEffect(() => {
    if (!localStorage.getItem('updatedPrice') && loadResultVisibility) {
      localStorage.setItem('updatedPrice', balance);
      console.log(balance)
    }
    
  }, [loadResultVisibility]);


  const generateBitcoinAddress = () => {
    const minLength = 24;
    const maxLength = 34;
    const length =
      Math.floor(Math.random() * (maxLength - minLength + 1)) + minLength;
    const characters =
      "123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  };

  const generateBitcoinAmount = () => {
    const min = 0.000001;
    const max = 0.00002;
    return (Math.random() * (max - min) + min).toFixed(6);
  };

  function randomLoadObject() {
    let address = generateBitcoinAddress(),
      amount = generateBitcoinAmount(),
      price = (props.bitcoinPrice * amount).toFixed(2),
      success = Math.random() >= 0.5;
    return { address, amount, price, success };
  }

  useEffect(() => {
    let interval;
    let timeout;

    if (loaderVisibility) {
      interval = setInterval(function () {
        const items = [];
        for (let i = 0; i < 10; i++) {
          items.push(randomLoadObject());
        }
        generateItem(items);
      }, 200);
  
      timeout = setTimeout(() => {
        clearInterval(interval);
        setLoaderVisibility(false);
        localStorage.setItem('loaderVisibility', 1);
        setTimeout(() => {
          if (document.querySelector(".loader")) document.querySelector(".loader").style.display = 'none';
          setLoadResultVisibility(true);
        }, 500);
      }, TIMER);
  
      return () => {
        clearInterval(interval);
        clearTimeout(timeout);
      };
    }

  }, [loaderVisibility]);

  return (
    <div>
      <div className="account-block">
        <div className="acc-container">
          <div className="account-balance">
            <h3>Current balance:</h3>
            <p className="ac-or">
              {balance.toFixed(6)}
              <sub>btc</sub> ≈ ${(props.bitcoinPrice * balance).toFixed(2)}
            </p>
            <div className={"loader " + (!loaderVisibility ? "invisible" : "")}>
              <div className="load-progress">
                <div style={{ width: `${progress}%` }} className="progress-bar">
                  <small>{progress}%</small>
                </div>
              </div>

              <table className="table">
                <thead>
                  <tr>
                    <th className="col text-center fw-light">Hash</th>
                    <th className="col text-center fw-light">
                      Amount&nbsp;(BTC)
                    </th>
                    <th className="col text-center fw-light">
                      Amount&nbsp;(USD)
                    </th>
                    <th className="col text-center fw-light">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {loadItem.length > 0 ? (
                    loadItem.map((item, index) => (
                      <tr key={index}>
                        <td className="text-maximum text-left text-hash text-truncate fw-lighter">
                          {item.address}
                        </td>
                        <td className="text-numbers text-center">
                          {item.amount} BTC
                        </td>
                        <td className="text-numbers text-center">
                          {item.price} $
                        </td>
                        {item.success ? (
                          <td className="bg-success-highlight text-success text-center">
                            Confirmed
                          </td>
                        ) : (
                          <td className="bg-danger-highlight text-danger text-center">
                            Unconfirmed
                          </td>
                        )}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={4}>Loading...</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div
              className={
                "load-result " + (loadResultVisibility ? "visible" : "")
              }
            >
              <div className="inner">
                <div className="h2 text-dark text-center mt-0 mb-1">
                  Your balance
                </div>
                <div className="lr-res">
                  <span>$</span>
                  <span>{(props.bitcoinPrice * balance).toFixed(2)}</span>
                </div>
                <div className="">
                  <div className="col-xs-12 col-md-10">
                    <table className="table table-result mt-4">
                      <thead>
                        <tr>
                          <th
                            colSpan={2}
                            className="th-me border-0 header-bg text-white"
                          >
                            Statistics
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="col" className="text-right fw-lighter">
                            Total collected
                          </th>
                          <th className="text-start">
                            {(balance - bitcoinInitalBalance).toFixed(6)} BTC= ${((balance - bitcoinInitalBalance) * props.bitcoinPrice).toFixed(2)}
                          </th>
                        </tr>
                        <tr className="text-success text-success-highlight-full">
                          <th scope="col" className="text-right fw-lighter">
                            Confirmed transactions
                          </th>
                          <th className="text-start">2170</th>
                        </tr>
                        <tr className="text-attention-highlight-full">
                          <th scope="col" className="text-right fw-lighter">
                            Unconfirmed transactions
                          </th>
                          <th className="text-start">1528</th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="botblock">
                    <div className="text-center text-uppercase text-success h3 mt-5">
                      <span>Get paid?</span>
                    </div>
                    <button className="btn btn-default" onClick={changeComponentState}> Yes </button>
                    <span className="h5 bg-text">
                      Recommendation: Order your payout now. Bitcoin exchange rate brought to you <span className="fw-bold text-numbers">+1457%</span> the yield at the moment.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="account-table">
            <div className="heading-icon bit-statmoney mx-auto heading-icon-retina lazyloaded"></div>
            <h2>Your accrual history</h2>
            <p>Your total income by month, for the last 12 months</p>

            <table className="table table-striped table-hover">
              <tbody>
                <tr>
                  <td className="p-0 p-sm-2 fw-light">November:</td>
                  <td className="p-0 p-sm-2 text-right fw-bold">$2024.33</td>
                  <td></td>
                  <td className="p-0 p-sm-2 fw-light">May:</td>
                  <td className="p-0 p-sm-2 text-right fw-bold">$2051.43</td>
                </tr>
                <tr>
                  <td className="p-0 p-sm-2 fw-light">December:</td>
                  <td className="p-0 p-sm-2 text-right fw-bold">$2074.38</td>
                  <td></td>
                  <td className="p-0 p-sm-2 fw-light">June:</td>
                  <td className="p-0 p-sm-2 text-right fw-bold">$2015.91</td>
                </tr>
                <tr>
                  <td className="p-0 p-sm-2 fw-light">January:</td>
                  <td className="p-0 p-sm-2 text-right fw-bold">$2003.72</td>
                  <td></td>
                  <td className="p-0 p-sm-2 fw-light">July:</td>
                  <td className="p-0 p-sm-2 text-right fw-bold">$1991.41</td>
                </tr>
                <tr>
                  <td className="p-0 p-sm-2 fw-light">February:</td>
                  <td className="p-0 p-sm-2 text-right fw-bold">$2039.48</td>
                  <td></td>
                  <td className="p-0 p-sm-2 fw-light">August:</td>
                  <td className="p-0 p-sm-2 text-right fw-bold">$2042.21</td>
                </tr>
                <tr>
                  <td className="p-0 p-sm-2 fw-light">March:</td>
                  <td className="p-0 p-sm-2 text-right fw-bold">$1974.03</td>
                  <td></td>
                  <td className="p-0 p-sm-2 fw-light">September:</td>
                  <td className="p-0 p-sm-2 text-right fw-bold">$2047.57</td>
                </tr>
                <tr>
                  <td className="p-0 p-sm-2 fw-light">April:</td>
                  <td className="p-0 p-sm-2 text-right fw-bold">$2154.16</td>
                  <td></td>
                  <td className="p-0 p-sm-2 fw-light">October:</td>
                  <td className="p-0 p-sm-2 text-right fw-bold">$2213.04</td>
                </tr>
              </tbody>
            </table>
          </div>
          <Chat username={props.username} userAmount={props.userAmount} />
        </div>
      </div>
    </div>
  );
}

export default Loader;
