import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getStorage } from "firebase/storage";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBhZ2iWjgX883h_f5iE4ZsXyiVwuS6oXiw",
  authDomain: "bitcoinbonus-6c192.firebaseapp.com",
  projectId: "bitcoinbonus-6c192",
  storageBucket: "bitcoinbonus-6c192.appspot.com",
  messagingSenderId: "445434998476",
  appId: "1:445434998476:web:9fa3df611c29034456dc69",
  measurementId: "G-J7ZFJXV8T4"
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const storage = getStorage(app);
