import { useState } from "react";
import { useLocation } from "react-router-dom";
import "./account.css";
import rexBit from "../../img/rex-bit.png";
import Chat from "../Chat/Chat";
import { useNavigate  } from 'react-router-dom';

function Account(props) {
  const navigate = useNavigate ();
  function changeComponentState(event) {
    event.preventDefault();
    localStorage.setItem('componentState', 2);
    navigate('/loader');
  }
  return (
    <div className="account-block">
      <div className="acc-container">
        <div className="account-balance">
          <h3>Current balance:</h3>
          <p className="ac-or">
            {props.balanceValue}<sub>btc</sub> ≈ ${(props.bitcoinPrice * props.balanceValue).toFixed(2)}
          </p>
          <div className="row">
            <div className="left">
              <div className="heading-icon bit-handbitcoin mx-auto heading-icon-retina lazyloaded"></div>
              <h4>Collect bonuses for the last 6 days</h4>
              <p className="text">
                The automatic collection of bitcoin bonuses will start. You will
                need to wait and not close the page until the final collection
                is complete. After auto-collection you will be able to withdraw
                funds by any convenient way
              </p>
              <p className="center">
                Click the button below to collect bitcoin bonuses
              </p>
              <div className="btn btn-default" onClick={changeComponentState}>
                <i className="fa-solid fa-gear"></i>{" "}
                <span> Collect bitcoin bonuses </span>
              </div>
            </div>
            <div className="right">
              <img src={rexBit} alt="" />
            </div>
          </div>
        </div>
        <div className="account-table">
          <div className="heading-icon bit-statmoney mx-auto heading-icon-retina lazyloaded"></div>
          <h2>Your accrual history</h2>
          <p>Your total income by month, for the last 12 months</p>

          <table className="table table-striped table-hover">
            <tbody>
              <tr>
                <td className="p-0 p-sm-2 fw-light">November:</td>
                <td className="p-0 p-sm-2 text-right fw-bold">$2024.33</td>
                <td></td>
                <td className="p-0 p-sm-2 fw-light">May:</td>
                <td className="p-0 p-sm-2 text-right fw-bold">$2051.43</td>
              </tr>
              <tr>
                <td className="p-0 p-sm-2 fw-light">December:</td>
                <td className="p-0 p-sm-2 text-right fw-bold">$2074.38</td>
                <td></td>
                <td className="p-0 p-sm-2 fw-light">June:</td>
                <td className="p-0 p-sm-2 text-right fw-bold">$2015.91</td>
              </tr>
              <tr>
                <td className="p-0 p-sm-2 fw-light">January:</td>
                <td className="p-0 p-sm-2 text-right fw-bold">$2003.72</td>
                <td></td>
                <td className="p-0 p-sm-2 fw-light">July:</td>
                <td className="p-0 p-sm-2 text-right fw-bold">$1991.41</td>
              </tr>
              <tr>
                <td className="p-0 p-sm-2 fw-light">February:</td>
                <td className="p-0 p-sm-2 text-right fw-bold">$2039.48</td>
                <td></td>
                <td className="p-0 p-sm-2 fw-light">August:</td>
                <td className="p-0 p-sm-2 text-right fw-bold">$2042.21</td>
              </tr>
              <tr>
                <td className="p-0 p-sm-2 fw-light">March:</td>
                <td className="p-0 p-sm-2 text-right fw-bold">$1974.03</td>
                <td></td>
                <td className="p-0 p-sm-2 fw-light">September:</td>
                <td className="p-0 p-sm-2 text-right fw-bold">$2047.57</td>
              </tr>
              <tr>
                <td className="p-0 p-sm-2 fw-light">April:</td>
                <td className="p-0 p-sm-2 text-right fw-bold">$2154.16</td>
                <td></td>
                <td className="p-0 p-sm-2 fw-light">October:</td>
                <td className="p-0 p-sm-2 text-right fw-bold">$2213.04</td>
              </tr>
            </tbody>
          </table>
        </div>
        <Chat username={props.username} userAmount={props.userAmount} />
      </div>
    </div>
  );
}

export default Account;
