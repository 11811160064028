import React, { useState, useEffect } from "react";
import "./notification.css";

const names = [
  "Alice",
  "Bob",
  "Charlie",
  "Dave",
  "Eve",
  "Frank",
  "Grace",
  "Heather",
  "Igor",
  "Jill",
  'Oliver',
  'Ava',
  'Ethan',
  'Olivia',
  'Liam',
  'Sophia',
  'Elijah',
  'Charlotte',
  'Logan',
  'Amelia',
  'Mason',
  'Mia',
  'Noah',
  'Aria',
  'Lucas',
  'Abigail',
  'James',
  'Emily',
  'Benjamin',
  'Elizabeth',
  'Michael',
  'Ella',
  'Henry',
  'Avery',
  'Jackson',
  'Sofia',
  'Sebastian',
  'Camila',
  'Aiden',
  'Aaliyah',
  'Matthew',
  'Harper',
  'Samuel',
  'Abella',
  'David',
  'Aurora',
  'Joseph',
  'Chloe',
  'Carter',
  'Evelyn',
  'Owen',
  'Kaylee',
  'Wyatt',
  'Natalie',
  'John',
  'Hannah',
  'Jack',
  'Aubrey',
  'Luke',
  'Avery',
  'Jayden',
  'Arianna',
  'Dylan',
  'Adalyn',
  'Grayson',
  'Riley',
];

function Notification() {


  const [username, setUsername] = useState("");
  const [receivedAmount, setReceivedAmount] = useState(0);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setTimeout(()=> {
      if (!isVisible) {
        setUsername(names[Math.floor(Math.random() * names.length)]);
        setReceivedAmount(Math.floor(Math.random() * (23000 - 20000 + 1)) + 20000);
      }
    }, 1000)
  }, [isVisible]);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsVisible(true);
      setTimeout(() => {
        setIsVisible(false);
      }, 10000);
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  function closeBlock() {
    setIsVisible(false);
  }
  return (
    <div
      className={`notification-block fade-in-out ${
        isVisible ? "fade-in" : "fade-out"
      }`}
    >
      <div className="row">
        <img
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAO3SURBVEhL7ZRtaFNXHMbFlvrWtDfvL7UV/LBv0+rH2cDwk5OCCrIpdjAQxlydooNZodlkTmmbqLFl3ezqcFatfaMtaY1uaIhuVmMqqVvrEjCd0sWsKnbm5Ta3TR/PPfcsTTWNGRPZB39wIQn3nB/P/zknc/CKeS186fx/hcKACbyjBJELBjw8p8fItU8wOjoKQRDYG5nxYmF8HOPn3gLftRgXa9Q4Us6h8gMZanbk49C+dTCZTGhoaIDL5WIL0pNWODXmA9+xDLfr9TBv5XCqQoGhH7SIXDQgclmPaH8xpoQgvF4vmpqa2Kr0pBXGeksxaDVg78Y8XLdqEe1djOiFArR8qaDCs9VyxP40s7czY1bh5O+n8aixEAc2c+g/rEOUjDTaU0A7NC7PoULjihzwQ++wFZkxqzBmW4/OTzXo2qMG315IO2ytVMK4bB6y5s6BsTgHRbosGFfm4MeOj9gqiba2NjgcDvZtJimFU2PDiDQuQeWGfIx+T5K1FSLa+XzCSJ8O4RtavPlGNvi7ZRDGmun6QCAAi8VCPz9LSuHk4Encri7AN1uV4JuLaEI6UtbhtJA8N3QofXsewr9pEPZqEPKrEQ2WwFy1BsFgkO04TUrhxM+f4xeTHi07VeDPkHGKCZM6XGrIhnlHHsy78vDemgX4eMvCaeEdNUL3VPiuLhce9/tsx2lSC3/ahismHdp3aRIJxQ55llAjn4syIipbu4AI56NcFA5qEwlD99Q48a0M1y5zmBAusV0lUgoF2yb07zfgxDYyUpJwRofnkzq8KnUojjREEoa8RHZHRRPWWXJxy81BiDWwXSVSJ3Qfwf26Qux/l8uoQ/HQJI90bFiFit0L8WBEgXG+iu0qkVIYHz4P/vgScmgUcFXpEh22sGuRnUWuBbmDRfoslJBrYW+UzxA6ujkc/zoXTx4oMxOKxLpL8avZgOoyOR43k/6SOpQSktESqZgw4iGjZR2OeFT44rNFGHIrEHqoJCM9w3aUmFU46e8Bf7IItgo1jpUr8Hd7AfhLqzDu2ohWczEd6dlqjnYY9mhph3951Dj6lQz21nw8CShpwnj8LttRYlahSHzEgYlbh2E79iGsB7bD5/PR36cmoxD+sCQ6FIXubgUO7slFz2mOntLQfRUmYl30/WTSCpMZGBhAbW0t6uvrYbfb0dfXB2fndnRY5ThUIYPVJMNNuzJxD2OhGrZyJhkL/8Hv98PpdMJms9HH2XsQPufqRId8YD2EsPQXl4p/LfyvvBa+dF6xEHgKO+XDaTDKX5gAAAAASUVORK5CYII="
          className="rounded me-2"
          alt="Ronald I"
          data-v-4abc1b02=""
        />
        <div className="name">{username}</div>
        
        <i onClick={closeBlock} className="fa-solid fa-xmark"></i>
      </div>
      <p>Received payment: {receivedAmount}$</p>
    </div>
  );
}

export default Notification;
