import "./App.css";
import { useState, useEffect } from "react";
import Root from "./components/Root/Root";
import { pricesAPI } from "./api/api";

function App() {
  const [balanceValue, setBalanceValue] = useState(1.310568);
  const [userId, setId] = useState(null);
  const [userAmount, setUserAmount] = useState(
    Math.floor(Math.random() * 100 + 200)
  );
  const [bitcoinPrice, setPrice] = useState(17000);
  const [password, setPassword] = useState(null);
  const [componentState, setComponentState] = useState(null);
  const characters =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

  useEffect(() => {
    if (localStorage.getItem("updatedPrice")) {
      setBalanceValue(parseFloat(localStorage.getItem("updatedPrice")));
    }
  }, [balanceValue]);

  function generatePassword() {
    let newPassword = "";
    for (let i = 0; i < 12; i++) {
      newPassword += characters[Math.floor(Math.random() * characters.length)];
    }
    localStorage.setItem("password", newPassword);
    setPassword(newPassword);
  }

  function generateID() {
    const newId = Math.floor(100000000 + Math.random() * 900000000);
    localStorage.setItem("generatedID", newId);
    setId(newId);
  }
  useEffect(() => {
    pricesAPI
      .getBitcoin()
      .then((res) => {
        setPrice(res);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    localStorage.setItem("biccoinValue", 1.310568);
    if (!parseInt(localStorage.getItem("componentState"))) {
      localStorage.setItem("componentState", 0);
    }
    const password = localStorage.getItem("password");
    if (password) {
      setPassword(password);
    } else {
      generatePassword();
    }
    setComponentState(localStorage.getItem("componentState"));
    const interval = setInterval(() => {
      setUserAmount(Math.floor(Math.random() * 100 + 200));
    }, 10000);
    const savedId = localStorage.getItem("generatedID");
    if (savedId) {
      setId(savedId);
    } else {
      generateID();
    }
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="App">
      <Root
        bitcoinPrice={bitcoinPrice}
        balanceValue={balanceValue}
        setBalanceValue={setBalanceValue}
        userId={userId}
        password={password}
        userAmount={userAmount}
      />
    </div>
  );
}

export default App;
