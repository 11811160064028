import "./header.css";
import logo from "../../img/logo.svg";
import React, { useState } from "react";

function Header(props) {
  const [time, newtime] = useState(useDate);



  setTimeout(() => {
    newtime(useDate);
  }, 1000);

  function useDate() {
    return new Date().toLocaleString("en-US", {
      day: "numeric",
      month: "long",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    });
  }


  return (
    <header className="header">
      <div className="header__inner">
        <div className="logo header__logo">
          <img src={logo} alt="BitBonus" />
          <span>Bitcoin Bonus</span>
        </div>
        <div className="header__greet">
          <div className="text">
            <span>Good evening, </span>
            <span className="login-name">user-id{props.userId}</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 256 512"
              fill="#ffffff"
              width="20px"
              height="20px"
            >
              <path
                fill="currentColor"
                d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"
              ></path>
            </svg>
          </div>
          <div className="online">
            <span>
              Online: <span className="fw-bold font-monospace scaleIn">{props.userAmount}</span>
            </span>
          </div>
        </div>
        <div className="header__wallet">
          <div className="top">
            $ <span>{(props.bitcoinPrice * (Number(props.balanceValue).toFixed(6))).toFixed(2)}</span>
            <i className="fa-sharp fa-solid fa-wallet"></i>
          </div>
          <p>
            Balance on <span className="timeToday">{time}</span>
          </p>
        </div>
      </div>
    </header>
  );
}

export default Header;
