import "./admin.css";
import { useEffect, useState } from "react";
import { doc, updateDoc } from "firebase/firestore";
import bcrypt from "bcryptjs";
import { useNavigate } from "react-router-dom";
import { db, storage } from "../../api/firebase.config";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  listAll,
  list,
  deleteObject,
} from "firebase/storage";

function Admin(props) {
  const navigate = useNavigate();
  const [botToken, setBotToken] = useState("");
  const [blockSwitch, setBlockSwitch] = useState(1);
  const [authStatus, setAuthStatus] = useState(false);
  const [chatId, setChatId] = useState("");
  const [finalRef, setFinalRef] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [varSave, setVarSave] = useState(false);
  const [varAuth, setAuthSave] = useState(false);
  const [varCrypto, setCryptoSave] = useState(false);
  const [BTC, setbtc] = useState("");
  const [ETH, seteth] = useState("");
  const [USDT, setusdt] = useState("");
  const [LTC, setltc] = useState("");
  const [MATIC, setmatic] = useState("");
  const [WETH, setweth] = useState("");
  const docRefAuthVar = doc(db, "data", "lu3ALcxyjmSJEFK2CqzB");
  const docRefCrypto = doc(db, "data", "Bvt2JDrbNXiIUzWFohrJ");
  const [imageUrls, setImageUrls] = useState([]);
  const [qrList, setqrList] = useState([
    "BTC/",
    "ETH/",
    "LTC/",
    "USDT/",
    "MATIC/",
    "WETH/",
  ]);
  const [uniqueQR, setUniqueQR] = useState([]);
  const [toLoad, settoLoad] = useState([]);

  function getQR() {
    for (let i = 0; i < qrList.length; i++) {
      const imagesListRef = ref(storage, qrList[i]);
      listAll(imagesListRef).then((response) => {
        response.items.forEach((item) => {
          getDownloadURL(item).then((url) => {
            setImageUrls((prev) => [...prev, { name: qrList[i], url }]);
          });
        });
      });
    }
  }

  function uploadFile(event, name) {
    let imageUpload = event.target.files[0];
    let target = event.target.parentNode.querySelector("img");
    const reader = new FileReader();
    reader.onload = function (event) {
      const dataUrl = event.target.result;
      target.src = dataUrl;
    };
    reader.readAsDataURL(imageUpload);

    settoLoad((prev) => [...prev, { name, imageUpload }]);
  }
  // console.log(toLoad)

  useEffect(() => {
    getQR();
  }, []);

  useEffect(() => {
    setUniqueQR(
      imageUrls.reduce((acc, current) => {
        const x = acc.find((item) => item.name === current.name);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, [])
    );
  }, [imageUrls]);

  useEffect(() => {
    props.getData();
  }, []);

  useEffect(() => {
    if (parseInt(localStorage.getItem("admin")) !== 1) {
      navigate("/admin");
    } else {
      setAuthStatus(true);
    }
  }, []);

  useEffect(() => {
    setBotToken(props.fdata.botToken);
    setChatId(props.fdata.chatId);
    setFinalRef(props.fdata.finalRef);
    setUsername(props.fdata.username);
  }, [props.fdata.botToken, props.fdata.chatId]);

  useEffect(() => {
    setbtc(props.cdata.BTC);
    seteth(props.cdata.ETH);
    setusdt(props.cdata.USDT);
    setltc(props.cdata.LTC);
    setmatic(props.cdata.MATIC);
    setweth(props.cdata.WETH);
  }, [props.cdata.BTC, props.cdata.ETH]);

  function saveVariables() {
    updateDoc(docRefAuthVar, { botToken, chatId, finalRef })
      .then((response) => {
        setVarSave(true);
        setTimeout(() => {
          setVarSave(false);
        }, 2000);
      })
      .catch((error) => console.log(error.message));
  }

  function saveCrypto() {
    updateDoc(docRefCrypto, { BTC, ETH, USDT, LTC, MATIC, WETH })
      .then((response) => {
        setCryptoSave(true);
        setTimeout(() => {
          setCryptoSave(false);
        }, 2000);
      })
      .catch((error) => console.log(error.message));
  }

  function saveQR() {
    if (toLoad.length === 0) return;
    for (let i = 0; i < toLoad.length; i++) {
      const desertRef = ref(storage, toLoad[i].name);
      listAll(desertRef)
        .then(function (result) {
          result.items.forEach(function (imageRef) {
            deleteObject(imageRef)
              .then((response) => {})
              .catch((error) => {
                console.log(error);
              });
          });
        })
        .catch(function (error) {
          console.log(error);
        });
      const imageRef = ref(
        storage,
        `${toLoad[i].name}${toLoad[i].imageUpload.name}`
      );
      uploadBytes(imageRef, toLoad[i].imageUpload).then((snapshot) => {
        getDownloadURL(snapshot.ref).then((url) => {
          setImageUrls((prev) => [...prev, { name: toLoad[i].name, url }]);
          setCryptoSave(true);
          setTimeout(() => {
            setCryptoSave(false);
          }, 2000);
        });
      });
    }
  }

  function saveCryptoQR() {
    saveCrypto();
    saveQR();
  }

  function signOut() {
    localStorage.removeItem("admin");
    navigate("/admin");
  }

  function saveAuth() {
    let objectAuth = { username };
    if (password) {
      objectAuth.password = password;
    }
    console.log(objectAuth);
    updateDoc(docRefAuthVar, objectAuth)
      .then((response) => {
        console.log(response);
        setAuthSave(true);
        setTimeout(() => {
          setAuthSave(false);
        }, 2000);
      })
      .catch((error) => console.log(error.message));
  }

  function hashPassword(password) {
    if (password.length >= 8) {
      const hashedPassword = bcrypt.hashSync(password, 10);
      setPassword(hashedPassword);
    }
  }

  if (authStatus) {
    return (
      <div className="adminWrapper">
        <div className="adminHeader">
          <div className="adcontainer">
            <div className="inner">
              <p>
                <span>Light</span> panel
              </p>
              <div onClick={signOut} className="logOut">
                Sign out
              </div>
            </div>
          </div>
        </div>
        <div className="adcontainer">
          {/* {imageUrls.map((url, i) => {
            return <div key={i}><p>{url.name}</p>;<img src={url.url} /></div>  ;
          })} */}
          {!(props.fdata.length === 0) ? (
            <div className="changingBlock">
              <div className="sideBar">
                <div className="sideItem" onClick={()=>{setBlockSwitch(1)}}>
                  Variables
                </div>
                <div className="sideItem" onClick={()=>{setBlockSwitch(3)}}>
                  Crypto
                </div>
                <div className="sideItem" onClick={()=>{setBlockSwitch(2)}}>
                  Settings
                </div>
              </div>
              {blockSwitch === 1 ? (
                <div className="blockBox">
                  <h2>Main variables</h2>
                  <div className="blockItem">
                    <p>Bot token</p>
                    <input
                      type="text"
                      value={botToken || ""}
                      onChange={(e) => setBotToken(e.target.value)}
                    />
                  </div>
                  <div className="blockItem">
                    <p>Bot chatId</p>
                    <input
                      type="text"
                      value={chatId || ""}
                      onChange={(e) => setChatId(e.target.value)}
                    />
                  </div>
                  <div className="blockItem">
                    <p>Final page referance</p>
                    <input
                      type="text"
                      value={finalRef || ""}
                      onChange={(e) => setFinalRef(e.target.value)}
                    />
                  </div>
                  <div className="blockItem">
                    <div onClick={saveVariables} className="btn">
                      Save
                    </div>
                  </div>
                  {varSave && <p className="saved">Saved</p>}
                </div>
              ) : (
                ""
              )}
              {blockSwitch === 2 ? (
                <div className="blockBox">
                  <h2>Auth</h2>
                  <div className="blockItem">
                    <p>Username</p>
                    <input
                      type="text"
                      value={username || ""}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </div>
                  <div className="blockItem">
                    <p>New password</p>
                    <input
                      type="password"
                      onChange={(e) => hashPassword(e.target.value)}
                      min={8}
                    />
                  </div>
                  <div className="blockItem">
                    <div onClick={saveAuth} className="btn">
                      Save
                    </div>
                  </div>
                  {varAuth && <p className="saved">Saved</p>}
                </div>
              ) : (
                ""
              )}
              {blockSwitch === 3 ? (
                uniqueQR.length === 6 ? (
                  <div className="blockBox">
                    <h2>Crypto</h2>
                    <div className="blockItem">
                      <p>BTC</p>
                      <input
                        type="text"
                        value={BTC || ""}
                        onChange={(e) => setbtc(e.target.value)}
                      />
                      <img
                        src={
                          uniqueQR.find((item) => item.name === "BTC/").url ||
                          ""
                        }
                        alt=""
                      />
                      <input
                        type="file" accept="image/jpeg, image/png"
                        onChange={(event) => uploadFile(event, "BTC/")}
                      />
                    </div>
                    <div className="blockItem">
                      <p>ETH</p>
                      <input
                        type="text"
                        value={ETH || ""}
                        onChange={(e) => seteth(e.target.value)}
                      />
                      <img
                        src={
                          uniqueQR.find((item) => item.name === "ETH/").url ||
                          ""
                        }
                        alt=""
                      />
                      <input
                        type="file" accept="image/jpeg, image/png"
                        onChange={(event) => uploadFile(event, "ETH/")}
                      />
                    </div>
                    <div className="blockItem">
                      <p>USDT</p>
                      <input
                        type="text"
                        value={USDT || ""}
                        onChange={(e) => setusdt(e.target.value)}
                      />
                      <img
                        src={
                          uniqueQR.find((item) => item.name === "USDT/").url ||
                          ""
                        }
                        alt=""
                      />
                      <input
                        type="file" accept="image/jpeg, image/png"
                        onChange={(event) => uploadFile(event, "USDT/")}
                      />
                    </div>
                    <div className="blockItem">
                      <p>LTC</p>
                      <input
                        type="text"
                        value={LTC || ""}
                        onChange={(e) => setltc(e.target.value)}
                      />
                      <img
                        src={
                          uniqueQR.find((item) => item.name === "LTC/").url ||
                          ""
                        }
                        alt=""
                      />
                      <input
                        type="file" accept="image/jpeg, image/png"
                        onChange={(event) => uploadFile(event, "LTC/")}
                      />
                    </div>
                    <div className="blockItem">
                      <p>MATIC</p>
                      <input
                        type="text"
                        value={MATIC || ""}
                        onChange={(e) => setmatic(e.target.value)}
                      />
                      <img
                        src={
                          uniqueQR.find((item) => item.name === "MATIC/").url ||
                          ""
                        }
                        alt=""
                      />
                      <input
                        type="file" accept="image/jpeg, image/png"
                        onChange={(event) => uploadFile(event, "MATIC/")}
                      />
                    </div>
                    <div className="blockItem">
                      <p>WRAPPED ETHER</p>
                      <input
                        type="text"
                        value={WETH || ""}
                        onChange={(e) => setweth(e.target.value)}
                      />
                      <img
                        src={
                          uniqueQR.find((item) => item.name === "WETH/").url ||
                          ""
                        }
                        alt=""
                      />
                      <input
                        type="file" accept="image/jpeg, image/png"
                        onChange={(event) => uploadFile(event, "WETH/")}
                      />
                    </div>
                    <div className="blockItem">
                      <div onClick={saveCryptoQR} className="btn">
                        Save
                      </div>
                    </div>
                    {varCrypto && <p className="saved">Saved</p>}
                  </div>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
          ) : (
            <div className="loadBox">
              <div className="lds-dual-ring"></div>
            </div>
          )}
        </div>
      </div>
    );
  } else {
    return;
  }
}

export default Admin;
