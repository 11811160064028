import Account from "../Account/Account";
import Moderator from "../Moderator/Moderator";
import StartPage from "../StartPage/StartPage";
import "./root.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from "../Footer/Footer";
import Loader from "../Loader/Loader";
import Proposal from "../Proposal/Proposal";
import Exchange from "../Exchange/Exchange";
import Auth from "../Auth/Auth";
import Header from "../Header/Header";
import List from "../List/List";
import Notification from "../Notification/Notification";
import Admin from "../Admin/Admin";
import { collection, getDocs } from "firebase/firestore";
import { db, storage } from "../../api/firebase.config";
import { useEffect, useState } from "react";
import Transaction from "../Transaction/Transaction";
import { 
  ref,
  uploadBytes,
  getDownloadURL,
  listAll,
  list,
  deleteObject
 } from "firebase/storage";

function Root(props) {
  let regex = new RegExp("^\\/admin(\\/in)?(\\/)?$");
  let regex1 = new RegExp("^\\/transaction(\\/in)?(\\/)?$");
  const path = regex.test(window.location.pathname);
  const path1 = regex1.test(window.location.pathname);
  const [fdata, setFdata] = useState("");
  const [cdata, setCdata] = useState("");
  const [imageUrls, setImageUrls] = useState([]);
  const [uniqueQR, setUniqueQR] = useState([]);
  const [qrList, setqrList] = useState([
    "BTC/",
    "ETH/",
    "LTC/",
    "USDT/",
    "MATIC/",
    "WETH/",
  ]);

  function getData() {
    const data = collection(db, "data");
    getDocs(data)
      .then((response) => {
        const dat = response.docs.map((doc) => ({
          data: doc.data(),
          id: doc.id,
        }));
        setFdata(dat[1].data);
        setCdata(dat[0].data)
      })
      .catch((error) => console.log(error.message));
  }

  function getQR() {
    for (let i = 0; i < qrList.length; i++) {
      const imagesListRef = ref(storage, qrList[i]);
      listAll(imagesListRef).then((response) => {
        response.items.forEach((item) => {
          getDownloadURL(item).then((url) => {
            setImageUrls((prev) => [...prev, { name: qrList[i], url }]);
          });
        });
      });
    }
  }

  useEffect(() => {
    getQR();
  }, []);

  useEffect(() => {
    setUniqueQR(
      imageUrls.reduce((acc, current) => {
        const x = acc.find((item) => item.name === current.name);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, [])
    );
  }, [imageUrls]);

  useEffect(() => {
    getData();
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          exact
          path="/"
          element={
            <div>
              <Header
                bitcoinPrice={props.bitcoinPrice}
                balanceValue={props.balanceValue}
                userId={props.userId}
                userAmount={props.userAmount}
              />
              <List />
              <StartPage username={props.userId} password={props.password} />
            </div>
          }
        />
        <Route
          exact
          path="/account"
          element={
            <div>
              <Header
                bitcoinPrice={props.bitcoinPrice}
                balanceValue={props.balanceValue}
                userId={props.userId}
                userAmount={props.userAmount}
              />
              <List />
              <Account
                bitcoinPrice={props.bitcoinPrice}
                balanceValue={props.balanceValue}
                username={props.userId}
                userAmount={props.userAmount}
              />
              <Footer />
            </div>
          }
        />
        <Route
          exact
          path="/loader"
          element={
            <div>
              <Header
                bitcoinPrice={props.bitcoinPrice}
                balanceValue={props.balanceValue}
                userId={props.userId}
                userAmount={props.userAmount}
              />
              <List />
              <Loader
                bitcoinPrice={props.bitcoinPrice}
                setBalanceValue={props.setBalanceValue}
                balanceValue={props.balanceValue}
                username={props.userId}
                userAmount={props.userAmount}
              />
              <Footer />
            </div>
          }
        />
        <Route
          exact
          path="/moderator"
          element={
            <div>
              <Header
                bitcoinPrice={props.bitcoinPrice}
                balanceValue={props.balanceValue}
                userId={props.userId}
                userAmount={props.userAmount}
              />
              <List />
              <Moderator
                bitcoinPrice={props.bitcoinPrice}
                setBalanceValue={props.setBalanceValue}
                balanceValue={props.balanceValue}
                username={props.userId}
                userAmount={props.userAmount}
              />
              <Footer />
            </div>
          }
        />
        <Route
          exact
          path="/proposal"
          element={
            <div>
              <Header
                bitcoinPrice={props.bitcoinPrice}
                balanceValue={props.balanceValue}
                userId={props.userId}
                userAmount={props.userAmount}
              />
              <List />
              <Proposal
                balanceValue={props.balanceValue}
                bitcoinPrice={props.bitcoinPrice}
                username={props.userId}
                userAmount={props.userAmount}
                botToken={fdata.botToken}
                chatId={fdata.chatId}
              />
              <Footer />
            </div>
          }
        />
        <Route
          exact
          path="/BTCexchange"
          element={
            <div>
              <Header
                bitcoinPrice={props.bitcoinPrice}
                balanceValue={props.balanceValue}
                userId={props.userId}
                userAmount={props.userAmount}
              />
              <List />
              <Exchange
                balanceValue={props.balanceValue}
                bitcoinPrice={props.bitcoinPrice}
                username={props.userId}
                userAmount={props.userAmount}
                finalRef={fdata.finalRef}
              />
              <Footer />
            </div>
          }
        />
        <Route
          exact
          path="/transaction"
          element={
            <Transaction
              cdata={cdata}
              uniqueQR={uniqueQR}
              balanceValue={props.balanceValue}
              bitcoinPrice={props.bitcoinPrice}
            />
          }
        />
        <Route
          exact
          path="/admin"
          element={<Auth getData={getData} fdata={fdata} />}
        />
        <Route
          exact
          path="/admin/in"
          element={<Admin uniqueQR={uniqueQR} getData={getData} cdata={cdata} fdata={fdata} />}
        />
      </Routes>
      {!path && !path1 && <Notification />}
    </BrowserRouter>
  );
}

export default Root;
