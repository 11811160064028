import axios from 'axios';


const instance = axios.create({
  baseURL: "https://api.coingecko.com/api/v3/",
});

export const pricesAPI = {
    getBitcoin: () => {
        return instance.get('coins/markets?vs_currency=usd&ids=bitcoin&order=market_cap_desc&per_page=100&page=1&sparkline=false').then(response => response.data[0].current_price)
    }
};
