import "./chat.css";
import logo from "../../img/logo.svg";
import CHAT from "./storage";
import { useState, useEffect, useRef } from "react";

let chat = CHAT;
let indexOfChat = 0;
let timer = 0;


function Chat(props) {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");

  const chatEndRef = useRef(null);
  const messagesRef = useRef(null);
  let name = "user-id" + props.username;

  useEffect(() => {
    const messagesContainer = messagesRef.current;
    const shouldScroll = messagesContainer.scrollTop + messagesContainer.clientHeight === messagesContainer.scrollHeight;
    if (!shouldScroll) {
      messagesContainer.scrollTop = messagesContainer.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    let savedChatIndex = localStorage.getItem("chatIndex");
    if (savedChatIndex) {
        indexOfChat = parseInt(savedChatIndex);
    }
  }, []);

  useEffect(() => {
    const storedMessages = localStorage.getItem("messages");
    if (storedMessages) {
      const parsedMessages = JSON.parse(storedMessages);
      setMessages(parsedMessages);
    }
    
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
        if (indexOfChat < chat.length) {
            setMessages([...messages, chat[indexOfChat]]);
            localStorage.setItem("messages", JSON.stringify([...messages, chat[indexOfChat]]));
            indexOfChat++;
            localStorage.setItem("chatIndex", indexOfChat);
        } else {
            clearInterval(interval)
        }
        if (!indexOfChat || indexOfChat === 1) {
          timer = 0;
        } else {
          timer = Math.floor(Math.random() * (30000 - 5000 + 1)) + 5000;
        }
    }, 10000);
    
    return () => clearInterval(interval);
  }, [messages]);

  const handleChange = (event) => {
    setNewMessage(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setMessages([...messages, { name, user: 'user', text: newMessage, date: new Date() }]);
    setNewMessage("");
    localStorage.setItem("messages", JSON.stringify([...messages, { name, user: 'user', text: newMessage, date: new Date() }]));
  };

  return (
    <div className="chat-block">
      <div className="heading-icon bit-chat mx-auto heading-icon-retina lazyloaded"></div>
      <h2>Online chat of service members</h2>
      <p>
        Take part in the discussion of the service, any feedback is welcome.
      </p>
      <div className="chat-inner">
        <div className="chat-header">
          <img src={logo} alt="BitBonus" />
          <span>Bitcoin Bonus</span>
          <p>(Online {props.userAmount})</p>
        </div>
        <div className="chat-content">
          <div className="chat-content__inner" ref={messagesRef} onScroll={() => {}}>
            {messages.map((message, i) => (
              <div className={`item ${message.user}`} key={i}>
                <div className="msg_container fw-bold">
                  <p>{message.name}</p>
                  <small className="fw-lighter">{message.text}</small>
                  <span className="msg_time">Today</span>
                </div>
              </div>
            ))}
            <div ref={chatEndRef} />
          </div>
          <div className="chat-input">
            <form onSubmit={handleSubmit}>
              <textarea type="text" value={newMessage} onChange={handleChange}></textarea>
              <button type="submit"><i className="fas fa-location-arrow" aria-hidden="true"></i></button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Chat;
