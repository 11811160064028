import "./coin.css";


function Coin({name, image, symbol, price, change}) {


  return (
    <div className="list__item">
        <div className="list__item_inner">
            <div className="list__item_content">
              <img width={20} height={20} src={image} alt="" />
              <div className="text">
                <p>{name}</p>
                <p>{symbol}</p>
              </div>
              <div className="value">
                <p className="price">
                  {price}$
                </p>
                <p className={change < 0 ? 'change low' : 'change'}>
                  <span className="arr">↓</span>
                  {change}%
                </p>
              </div>
            </div>
        </div>
    </div>
  );
}

export default Coin;


