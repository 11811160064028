import "./login.css";
import React, { useState } from "react";
import logLogo from "../../../img/logLogo.png";
import { useNavigate  } from 'react-router-dom';


function Login(props) {
  const [signOn, setSignOn] = useState(false);
  const [googleIn, setGoogleIn] = useState(false);
  const navigate = useNavigate ();


  function signInError(event) {
    event.preventDefault();
    setSignOn(true);
    setTimeout(() => {
      setSignOn(false);
    }, 1500);
  }
  function googleInError(event) {
    event.preventDefault();
    setGoogleIn(true);
    setTimeout(() => {
      setGoogleIn(false);
    }, 1500);
  }
  function pushOff() {
    setSignOn(false);
    googleIn(false);
  }
  function changeComponentState(event) {
    event.preventDefault();
    localStorage.setItem('componentState', 1);
    navigate('/account');
  }

  return (
      <div className="loginForm">
        <div className="loginForm__inner">
          <p className="name">Log in</p>
          <div className="logo">
            <img width={200} src={logLogo} alt="" />
          </div>
          <form>
            <div className="input-wrapper">
              <label htmlFor="username">Username</label>
              <input
                type="text"
                readOnly
                value={"user-id" + props.username ?? ""}
              />
            </div>
            <div className="input-wrapper">
              <label htmlFor="username">Password</label>
              <input type="text" readOnly value={props.password ?? ""} />
            </div>
            {signOn ? (
              <div className="push" role="alert">
                <div>
                  <i className="fa-solid fa-triangle-exclamation"></i> Your account
                  has already been created
                </div>
                <i onClick={pushOff} className="fa-solid fa-xmark"></i>
              </div>
            ) : (
              ""
            )}

            {googleIn ? (
              <div className="push google-push" role="alert">
                <div> No connection to server </div>
                <i onClick={pushOff} className="fa-solid fa-xmark"></i>
              </div>
            ) : (
              ""
            )}
            <button onClick={changeComponentState} className="logIn btn btn-default">Log in</button>
            <button onClick={signInError} className="singIn btn btn-outline">
              Registration
            </button>
            <p className="or">OR</p>
            <button onClick={googleInError} className="google-in btn">
              <img
                data-src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAA/1BMVEUAAAA/hPRChPRBhfPqQjXpQjQzp1EzplFBhfTqQjQzqFJDhvftRDHsPTE0qFE0qFL5uwQzqFHqQjQzp1HpQjQzp1P5uwPpQzUzqFIzqFH6uwTqQzU0p1LqQjQzqFHqQzXqQjX6uwToRDPsQzZBgvH3uAf/tgAsplhLh/8yqlT5twVChPPtuQnqQjXrRzM1o2tBhe5BhfQzqFHpQjTqQzQ0p1ForDzuXSrpQjRChfRChPPwcSLpQjV3rzczp1JChfRChPVBhvQ3m4LpPzEtpUvfPz//VVVChfTqQzU0qFL6uwRAi9k9krk5mpI1p1hIqkqesijxdSDLtxb0khX4qQsQygAsAAAAR3RSTlMAMNCB7Pz7G+6fch4bDfLt7OXj2NfTwLOpoH1wXVtWVUk1LSknJA4LCAf++vr08+nl2tra0LW0tKmmopuZhnt6aF0pJBEIA+HJ97MAAAD4SURBVCjPdc7pVsIwEAXgK7SlBQFBERBBFFBw3/d9axL35f2fhUzSDvRw+P7k5N6cmSAy26iWXeEuBO1XaBwHroiVm2ArjhhXzcNqiCSnE70Xhl5w7Jh8LZq/I8hRkwZ0rjnHmSDBC6z2E6ze5o/OzzFhSak/sZvHhEWl1P8VjO4M62JOaaswUiHzocg6F2xqMW3Ullm+fQPjPk0uqTig7/5+FZ8xkqbiAj31LaWsgeV8Kh6AU0nqgzg3i/w+kClIcri8AX3J7r1RcQetJS1vvlLSx+d7GKb6IFmZ9LGfg9UqJorKI2KZusdx4XYARltPSp5eU6M/kCGngk5ADXSXJAAAAABJRU5ErkJggg=="
                alt=""
                className="ls-is-cached lazyloaded"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAA/1BMVEUAAAA/hPRChPRBhfPqQjXpQjQzp1EzplFBhfTqQjQzqFJDhvftRDHsPTE0qFE0qFL5uwQzqFHqQjQzp1HpQjQzp1P5uwPpQzUzqFIzqFH6uwTqQzU0p1LqQjQzqFHqQzXqQjX6uwToRDPsQzZBgvH3uAf/tgAsplhLh/8yqlT5twVChPPtuQnqQjXrRzM1o2tBhe5BhfQzqFHpQjTqQzQ0p1ForDzuXSrpQjRChfRChPPwcSLpQjV3rzczp1JChfRChPVBhvQ3m4LpPzEtpUvfPz//VVVChfTqQzU0qFL6uwRAi9k9krk5mpI1p1hIqkqesijxdSDLtxb0khX4qQsQygAsAAAAR3RSTlMAMNCB7Pz7G+6fch4bDfLt7OXj2NfTwLOpoH1wXVtWVUk1LSknJA4LCAf++vr08+nl2tra0LW0tKmmopuZhnt6aF0pJBEIA+HJ97MAAAD4SURBVCjPdc7pVsIwEAXgK7SlBQFBERBBFFBw3/d9axL35f2fhUzSDvRw+P7k5N6cmSAy26iWXeEuBO1XaBwHroiVm2ArjhhXzcNqiCSnE70Xhl5w7Jh8LZq/I8hRkwZ0rjnHmSDBC6z2E6ze5o/OzzFhSak/sZvHhEWl1P8VjO4M62JOaaswUiHzocg6F2xqMW3Ullm+fQPjPk0uqTig7/5+FZ8xkqbiAj31LaWsgeV8Kh6AU0nqgzg3i/w+kClIcri8AX3J7r1RcQetJS1vvlLSx+d7GKb6IFmZ9LGfg9UqJorKI2KZusdx4XYARltPSp5eU6M/kCGngk5ADXSXJAAAAABJRU5ErkJggg=="
              />
              <span>Login with Google</span>
            </button>
          </form>
        </div>
      </div>
  );
}

export default Login;
